import moment from 'moment';
import 'moment/locale/fr';
import { queryEmailRegister } from './typeDefs';
import sendEmail from '../sendEmail';

moment.locale('fr');

const sendMailsRegistred = async (episodeId: string, emails: string[]) => {
  // Envoi des mails d'inscriptions aux volontaires qui ont été ajoutés à l'épisode.
  if (emails.length > 0) {
    await Promise.all(
      emails.map(email =>
        sendEmail(queryEmailRegister, {
          id: episodeId,
          recipient: email,
        }),
      ),
    );
  }
};

export default sendMailsRegistred;
