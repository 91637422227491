import React from 'react';
import jsonExport from 'jsonexport';
import {
  SpeakerEvaluationExportable,
  importExportLevels,
  downloadCSVForExcel,
  getEstablishmentName,
} from 'components/exporter';
import { Button, useNotify } from 'react-admin';
import logger from 'technical/logger';
import config from '../config';
import {
  getHighestRole,
  getRoles,
  shouldSeeAllRegionsRoles,
} from '../technical/auth/services';
import { categories } from '../business/establishment/resource';
import i18nProvider from '../translation';

const QuerySpeakerEvaluationExport = async function QueryExport(
  regionId: string | null,
  dateStart: string | null,
  dateEnd: string | null,
) {
  const query = `query Export($regionId: uuid, $dateStart: timestamptz, $dateEnd: timestamptz) {
    speakerEvaluation: speakerEvaluation2425(where: {_and: {episode: {class: {establishment: {regionId: {_eq: $regionId}}}, dateStart: {_gt: $dateStart}, dateEnd: {_lte: $dateEnd}}}}) {
      intervenant {
        firstName
        lastName
        origin
        site {
          organisationName
        }
        type
      }
      pathChosen
      enoughTime
      startOnTime
      sameTeacher
      helpFacilitateSession
      studentAttention
      studentInvolvement
      resolutionOrChallenge
      resolutionOrChallengeHolding
      computer
      videoProjector
      technicalIssueEJ
      technicalIssueRoom
      overallImpression
      comments
      episode {
        number
        class {
          name
          level
          type
          establishment {
            name
            postalCode
            city
            category
            referenceAcademy
            region {
              name
            }
          }
        }
      }
    }
  }
`;

  const variables = { regionId, dateStart, dateEnd };

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

const FlattenSpeakerEvaluation = async function FlattenSpeakerEvaluation(
  regionId: string | null,
  dateStart: string | null,
  dateEnd: string | null,
): Promise<SpeakerEvaluationExportable[]> {
  const response = await QuerySpeakerEvaluationExport(
    regionId,
    dateStart,
    dateEnd,
  );

  const speakerEvaluationsData = await response.json().then(res => {
    return res.data.speakerEvaluation;
  });

  return speakerEvaluationsData.map(evalData => {
    const episodeData = evalData?.episode;
    const classData = evalData?.episode?.class;
    const establishment = evalData?.episode?.class?.establishment;
    const volunteer = evalData?.intervenant;

    return {
      region: {
        name: String(establishment?.region?.name),
      },
      volunteer: {
        lastName: volunteer?.lastName
          ? volunteer.lastName
          : 'Intervenant pas dans la région',
        firstName: volunteer?.firstName ? volunteer.firstName : '',
        origin: volunteer?.origin
          ? i18nProvider.translate(`user.origins.${volunteer.origin}`)
          : '',
        company: volunteer?.site?.organisationName
          ? volunteer.site?.organisationName
          : '',
        type: volunteer?.type
          ? i18nProvider.translate(`user.types.${volunteer.type}`)
          : '',
      },
      establishment: {
        name: getEstablishmentName(establishment),
        city: establishment.city ? establishment.city : '',
        postalCode: establishment.postalCode
          ? `="${establishment.postalCode}"`
          : '',
        academy: establishment.referenceAcademy
          ? establishment.referenceAcademy
          : '',
        category: establishment.category
          ? categories[establishment.category]
          : '',
      },
      class: {
        name: classData.name,
        level: Object.keys(importExportLevels).find(
          key => importExportLevels[key] === classData.level,
        ),
        type: i18nProvider.translate(`resources.class.types.${classData.type}`),
      },
      episode: {
        number: episodeData.number,
      },
      pathChosen: evalData.pathChosen,
      enoughTime: evalData.enoughTime,
      startOnTime: evalData.startOnTime,
      sameTeacher: evalData.sameTeacher,
      helpFacilitateSession: evalData.helpFacilitateSession,
      studentAttention: evalData.studentAttention,
      studentInvolvement: evalData.studentInvolvement,
      resolutionOrChallenge: evalData.resolutionOrChallenge,
      resolutionOrChallengeHolding: evalData.resolutionOrChallengeHolding,
      computer: evalData.computer,
      videoProjector: evalData.videoProjector,
      technicalIssueEJ: evalData.technicalIssueEJ,
      technicalIssueRoom: evalData.technicalIssueRoom,
      overallImpression: evalData.overallImpression,
      comments: evalData.comments,
    };
  });
};

const speakerEvalExportFunction = async function speakerEvalExport(
  regionId: string | null,
  dateStart: string | null,
  dateEnd: string | null,
): Promise<string> {
  return jsonExport(
    await FlattenSpeakerEvaluation(regionId, dateStart, dateEnd),
    {
      rename: [
        'Région',
        'Nom intervenant',
        'Prénom intervenant',
        'Origine',
        'Entreprise',
        'Type',
        'Etablissement',
        'Ville',
        'Code Postal',
        'Academie',
        'Catégorie',
        'Nom de la classe',
        'Niveau',
        'Type',
        "Numéro d'épisode",
        'Thématique choisie',
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.sameTeacher`,
        ),
        i18nProvider.translate(`resources.speakerEvaluation.fields.enoughTime`),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.startOnTime`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.helpFacilitateSession`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.studentAttention`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.studentInvolvement`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.resolutionOrChallenge`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.resolutionOrChallengeHolding`,
        ),
        i18nProvider.translate(`resources.speakerEvaluation.fields.computer`),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.videoProjector`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.technicalIssueEJ`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.technicalIssueRoom`,
        ),
        i18nProvider.translate(
          `resources.speakerEvaluation.fields.overallImpression`,
        ),
        i18nProvider.translate(`resources.speakerEvaluation.fields.comments`),
      ],
      rowDelimiter: ';',
    },
  );
};

const SpeakerEvalExportButton = props => {
  const { regionId, record, dateStart, dateEnd } = props;
  const roles = getRoles();
  let region = null;
  if (regionId) {
    region = regionId;
  } else if (record && record.id) {
    region = record.id;
  }
  if (!shouldSeeAllRegionsRoles(roles) && region === null) {
    throw new Error(`Vous n'avez pas accès à ces données`);
  }
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const speakerEvalExport = async () => {
    setLoading(true);
    try {
      await speakerEvalExportFunction(region, dateStart, dateEnd).then(csv =>
        downloadCSVForExcel(
          csv,
          `eval_volontaires_${
            dateStart ? 'saison_en_cours' : 'saisons_précédentes'
          }`,
        ),
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        logger.info(e.message);
      }
      notify("Une erreur est survenue lors de l'export", 'warning');
    }
  };
  return (
    <Button
      label={`resources.speakerEvaluation.export_button_${
        dateStart ? 'current' : 'previous'
      }`}
      onClick={e => {
        e.stopPropagation();
        speakerEvalExport();
      }}
      disabled={loading}
    />
  );
};

export default SpeakerEvalExportButton;
