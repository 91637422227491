import { Auth0DecodedHash } from 'auth0-js';
import { ALLOWED_ROLES, ROLES } from './config';
// Array key used as weight for roles order, 0 as the highest
// To add a role, put it at the right place
// (usualy never before admin nor data-manager)
export const ROLES_RANKING = [
  ROLES['data-manager'],
  ROLES.national,
  ROLES['region-manager'],
  ROLES.operator,
  ROLES.user,
];

export function getAuthResult(): Auth0DecodedHash | null {
  const rawAuthResult = localStorage.getItem('authResult');
  if (!rawAuthResult) {
    return null;
  }

  try {
    return JSON.parse(rawAuthResult);
  } catch (e) {
    return null;
  }
}

export function getRoles() {
  const authResult = getAuthResult();
  const roles =
    authResult?.idTokenPayload['https://hasura.io/jwt/claims'][
      'x-hasura-allowed-roles'
    ] || [];

  return roles;
}

export function getHighestRole(roles) {
  const intersect = ROLES_RANKING.filter(role => roles.includes(role));
  return intersect[0];
}

export function isAllowed(roles: string[]) {
  return ALLOWED_ROLES.some(allowedRole =>
    roles.find(role => role === allowedRole),
  );
}

export function isDataManager(roles: string[]) {
  return roles.includes(ROLES['data-manager']);
}

export function isRegionManager(roles: string[]): boolean {
  return roles.includes(ROLES['region-manager']);
}

export function isNationalUser(roles: string[]): boolean {
  return roles.includes(ROLES.national);
}
export function isShowOnlyRoles(roles: string[]): boolean {
  return isNationalUser(roles) || isRegionManager(roles);
}

export function shouldSeeAllRegionsRoles(roles: string[]): boolean {
  return isNationalUser(roles) || isDataManager(roles);
}

export function isOperator(roles: string[]): boolean {
  return roles.includes(ROLES.operator);
}

export function additionalShowProps(roles: string[]) {
  return isShowOnlyRoles(roles) ? { actions: null } : {};
}

export function additionalUserShowProps(roles: string[]) {
  return isShowOnlyRoles(roles) || isOperator(roles) ? { actions: null } : {};
}

export function getRegionRattachement() {
  const authResult = getAuthResult();
  const regionId =
    authResult?.idTokenPayload['https://hasura.io/jwt/claims'][
      'x-hasura-region-rattachement'
    ];

  return regionId;
}

export function getCurrentEmail() {
  const authResult = getAuthResult();
  const email =
    authResult?.idTokenPayload['https://hasura.io/jwt/claims'][
      'x-hasura-email'
    ];

  return email;
}
