import React from 'react';
import { Layout, Notification } from 'react-admin';
import CustomMenu from './menu';
import MyAppBar from './appBar';

const MyNotification = props => (
  <Notification
    {...props}
    autoHideDuration={4000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  />
);
const MyLayout = props => (
  <Layout
    {...props}
    menu={CustomMenu}
    notification={MyNotification}
    appBar={MyAppBar}
  />
);

export default MyLayout;
