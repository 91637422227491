import React from 'react';
import { TextInput } from 'react-admin';

const PartialSearchInputStartWith = ({ source, label = '', ...props }) => {
  return (
    <TextInput
      // this line send a request of the form source : {_ilike: {eq:value}}} to hasura
      source={`${source}._ilike`}
      // this line add % to partial search
      parse={value => `${value}%`}
      format={value => (value ? value.replace(/%/g, '') : '')}
      label={label || `resources.${props?.resource}.fields.${source}`}
      {...props}
    />
  );
};

export default PartialSearchInputStartWith;
