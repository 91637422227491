import config from 'config';
import React, { useEffect } from 'react';
import { getHighestRole, getRoles } from 'technical/auth/services';

type UserFormContextType = {
  canChangeRegion: boolean;
};

export const UserFormContext = React.createContext<UserFormContextType>({
  canChangeRegion: false,
});

export function UserFormContextProvider({
  children,
  userId,
  create = false,
}: {
  children: React.ReactNode;
  userId: string;
  create?: boolean;
}) {
  const [canChangeRegion, setCanChangeRegion] = React.useState(false);
  useEffect(() => {
    if (create) {
      setCanChangeRegion(true);
    } else {
      const query = `query FindFormationsToCome($userId: uuid!) {
      user_training(where: {
        user_id: {_eq: $userId},
        invalidationDate: {_is_null: true}, 
        validationDate: {_is_null: true}
      }) {
        training {
          id
        }
      }
    }`;

      const variables = {
        userId,
      };

      fetch(`${config.graphqlUri}/v1/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Hasura-Role': getHighestRole(getRoles()),
        },
        body: JSON.stringify({ query, variables }),
      })
        .then(response => response.json())
        .then(response => {
          setCanChangeRegion(response.data.user_training.length === 0);
        })
        .catch(error => {
          console.error('Error:', error);
          setCanChangeRegion(false);
        });
    }
  }, [userId]);

  const value: UserFormContextType = {
    canChangeRegion,
  };
  return (
    <UserFormContext.Provider value={value}>
      {children}
    </UserFormContext.Provider>
  );
}
