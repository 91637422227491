import sendMailsUnregistred from './sendMailsUnregistred';
import sendMailsPeopleUpdate from './sendMailsPeopleUpdate';
import sendMailsEpisodeUpdate from './sendMailsEpisodeUpdate';
import { EpisodeDetails, VolontairesEpisode } from './typeDefs';
import sendMailsRegistred from './sendMailsRegistred';

export const getOne = async function getOne(
  resource: string,
  params: { id?: string },
  requestHandler: any,
) {
  return (await requestHandler('GET_ONE', resource, params)).data;
};

const update = async function update(
  resource: string,
  params: {
    data: { publicationDate: null };
    id: string;
    previousData: { publicationDate: any };
  },
  requestHandler: any,
) {
  return (await requestHandler('UPDATE', resource, params)).data;
};

interface EpisodeData {
  id: string;
  number: number;
  classId: string;
  classroomNumber: string;
  establishmentId: string;
  firstAnimateurId: string;
  secondAnimateurId: string;
  expertId: string;
  teacherId: string;
  firstGuest: string;
  secondGuest: string;
  dateStart: string | null;
  dateEnd: string | null;
}

const fetchEpisodeDetails = async (
  episode: any,
  requestHandler: any,
): Promise<EpisodeDetails> => {
  const pack = await getOne('class', { id: episode.classId }, requestHandler);
  const establishment = await getOne(
    'establishment',
    { id: pack.establishmentId },
    requestHandler,
  );

  const region = await getOne(
    'region',
    { id: establishment.regionId },
    requestHandler,
  );

  const regionManager = await getOne(
    'user',
    { id: region.managerId },
    requestHandler,
  );

  const teacher = await getOne(
    'contact',
    { id: episode.teacherId },
    requestHandler,
  );

  return { pack, establishment, region, regionManager, teacher };
};

const fetchVolontaires = async (
  episode: any,
  requestHandler: any,
): Promise<VolontairesEpisode> => {
  const firstAnimateur = episode.firstAnimateurId
    ? await getOne('user', { id: episode.firstAnimateurId }, requestHandler)
    : undefined;
  const secondAnimateur = episode.secondAnimateurId
    ? await getOne('user', { id: episode.secondAnimateurId }, requestHandler)
    : undefined;
  const expert = episode.expertId
    ? await getOne('user', { id: episode.expertId }, requestHandler)
    : undefined;

  return { firstAnimateur, secondAnimateur, expert };
};

const getMailsUpdate = (
  episode: any,
  volontaires: VolontairesEpisode,
): string[] => {
  const users = [
    volontaires.firstAnimateur,
    volontaires.secondAnimateur,
    volontaires.expert,
  ];
  const emailsUsers = users
    .filter(user => !!user && user.isActive)
    .map(user => user.email);

  emailsUsers.push(episode.firstGuest);
  emailsUsers.push(episode.secondGuest);

  return emailsUsers
    .filter(email => !!email) // remove null
    .filter((value, index, self) => self.indexOf(value) === index); // remove duplicates
};

// Fetch l'ancien user si il a été modifié ou supprimé durant l'update de l'épisode
const fetchUserIfNecessary = async (
  requestHandler: any,
  registered: boolean,
  previousId?: string,
  currentId?: string,
) => {
  const idToFetch = registered ? currentId : previousId;

  if (idToFetch && previousId !== currentId) {
    return getOne('user', { id: idToFetch }, requestHandler);
  }

  return undefined;
};

const getMailsNotification = async (
  previousEpisode: any,
  registred: boolean,
  episode: any,
  requestHandler: any,
): Promise<string[]> => {
  const oldFirstAnimateur = await fetchUserIfNecessary(
    requestHandler,
    registred,
    previousEpisode.firstAnimateurId,
    episode.firstAnimateurId,
  );

  const oldSecondAnimateur = await fetchUserIfNecessary(
    requestHandler,
    registred,
    previousEpisode.secondAnimateurId,
    episode.secondAnimateurId,
  );
  const oldExpert = await fetchUserIfNecessary(
    requestHandler,
    registred,
    previousEpisode.expertId,
    episode.expertId,
  );

  return [oldFirstAnimateur, oldSecondAnimateur, oldExpert]
    .filter(user => user && user.isActive)
    .map(user => user.email)
    .filter((value, index, self) => self.indexOf(value) === index); // remove duplicates;
};

const episodeUpdate = async function episodeUpdate(
  previousEpisode: EpisodeData,
  episode: EpisodeData,
  requestHandler: any,
) {
  console.log(' HELLO UPDATE ');
  const episodeDetails = await fetchEpisodeDetails(episode, requestHandler);
  const volontairesNewEpisode = await fetchVolontaires(episode, requestHandler);
  const emailsUpdate = getMailsUpdate(episode, volontairesNewEpisode);
  const emailsUnregistred = await getMailsNotification(
    previousEpisode,
    false,
    episode,
    requestHandler,
  );

  const emailsRegistred = await getMailsNotification(
    previousEpisode,
    true,
    episode,
    requestHandler,
  );

  // Depublie un pack si un épisode ne contient pas de date
  if (episode.dateStart === null || episode.dateEnd === null) {
    await update(
      'class',
      {
        id: episode.classId,
        previousData: { publicationDate: episodeDetails.pack.publicationDate },
        data: { publicationDate: null },
      },
      requestHandler,
    );
  }

  // Envoie du mail prevenant la modification de la date de l'épisode
  await sendMailsEpisodeUpdate(
    previousEpisode,
    episode,
    episodeDetails,
    emailsUpdate,
  );

  // Envoie du mail prévenant d'un changement de volontaire sur un épisode
  await sendMailsPeopleUpdate(
    previousEpisode,
    episode,
    episodeDetails,
    volontairesNewEpisode,
    emailsUpdate,
  );

  // Envoie d'un mail prévenant la désincriptions aux volontaires qui ont été retirés d'un épisode
  await sendMailsUnregistred(episode, episodeDetails, emailsUnregistred);

  // Envoie d'un mail prévenant l'inscription aux volontaires qui ont été ajoutés à un épisode
  await sendMailsRegistred(episode.id, emailsRegistred);

  if (
    (episode.dateStart === null || episode.dateEnd === null) &&
    episodeDetails.pack.publicationDate &&
    window.location.pathname === '/class'
  ) {
    window.location.reload();
  }
};

export const episodeCreate = async function episodeCreate(
  episode: EpisodeData,
  requestHandler: any,
) {
  const emailsRegistred = await Promise.all(
    [episode.firstAnimateurId, episode.expertId, episode.secondAnimateurId]
      .filter(value => value != null)
      .map(val =>
        getOne('user', { id: val }, requestHandler).then(data => data.email),
      ),
  );

  // Envoie d'un mail prévenant l'inscription aux volontaires qui ont été ajoutés à un épisode
  await sendMailsRegistred(episode.id, emailsRegistred);
};

export default episodeUpdate;
