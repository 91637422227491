import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const formatDate = (date: any): string => {
  return moment(date).format('dddd DD MMMM YYYY [à] HH[:]mm') || '';
};

export default formatDate;
