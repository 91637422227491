import React from 'react';
import { FunctionField } from 'react-admin';
import PropTypes from 'prop-types';
import moment from 'moment';

const TrainingStatusField = ({ ...props }) => {
  return (
    <FunctionField
      {...props}
      render={(record: any) => {
        if (record.validationDate) {
          return `Validé le ${moment(record.validationDate).format(
            'DD/MM/YYYY',
          )}`;
        }
        if (record.invalidationDate) {
          return `Invalidé le ${moment(record.invalidationDate).format(
            'DD/MM/YYYY',
          )}`;
        }
        return 'A traiter';
      }}
    />
  );
};

TrainingStatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
};

TrainingStatusField.defaultProps = {
  addLabel: true,
  label: 'Statut',
};
export default TrainingStatusField;
