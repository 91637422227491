import moment from 'moment';
import 'moment/locale/fr';
import { getCurrentEmail } from 'technical/auth/services';
import sendEmail from '../sendEmail';
import { UserData, queryEmailRegionsAnimationDelete } from './typeDefs';

moment.locale('fr');

const sendMailsRegionsAnimationDelete = async (
  user: UserData,
  regionRattachementId: string,
  oldRegionId: string,
) => {
  const payload = {
    userInfo: `${user.firstName} ${user.lastName}`,
    userMail: user.email,
    adminMail: getCurrentEmail(),
    regionId: regionRattachementId,
    oldRegionId,
  };

  // Mail au volontaire
  await sendEmail(queryEmailRegionsAnimationDelete, {
    ...payload,
  });
};

export default sendMailsRegionsAnimationDelete;
