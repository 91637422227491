import React from 'react';
import { DateInput as DInput } from '@sklinet/react-admin-date-inputs';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    minHeight: '52px',
    '& div': {
      minHeight: '48px',
      '& input': {
        paddingTop: '23px',
        paddingBottom: '6px',
        paddingLeft: '12px',
      },
    },
  },
});

function DateInput(props: any) {
  const style = useStyles();
  const { fixedHour, fixedMinute, ...propsToGive } = props;

  return (
    <DInput
      className={style.root}
      parse={value => {
        if (fixedHour !== undefined && fixedMinute !== undefined && value) {
          const updatedValue = moment(value)
            .hour(fixedHour)
            .minute(fixedMinute)
            .toISOString();
          return updatedValue;
        }
        return value || null;
      }}
      providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
      options={{
        format: 'dd/MM/yyyy',
        clearable: true,
      }}
      {...propsToGive}
    />
  );
}

// Change date

export const fiveJuly = () => {
  const july = moment('07-05', 'MM-DD');
  // substract ajouté afin que le changement soit effectif le 6 juillet (année scolaire du 5 au 5 compris, incohérence fonctionnelle)
  // Il faudra revoir cette fonctionnalité
  const lastJuly =
    july < moment().subtract(1, 'days') ? july : july.subtract(1, 'years');
  return lastJuly;
};
export const firstDayOfTheYear = fiveJuly()
  .hour(0)
  .minute(0);
export const firstDayOfTheYearToString = firstDayOfTheYear.toISOString();

export const lastDayOfTheYear = fiveJuly()
  .add(1, 'years')
  .hour(23)
  .minute(59);

export const lastDayOfTheYearToString = lastDayOfTheYear.toISOString();

export default DateInput;
