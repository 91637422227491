import React from 'react';
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  Filter,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import RegionFilter from 'components/RegionFilter';
import TranslateField from '../../components/translateField';
import {
  additionalShowProps,
  getRoles,
  isDataManager,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from '../../technical/auth/services';
import { AddressField, ListActions } from '../../components';
import TranslateChoices from '../../components/TranslateChoices';
import PartialSearchInput from '../../components/PartialSearchInput';
import { downloadCSVForExcel, exporter } from '../../components/exporter';

const origin = [
  'partner-company',
  'egee',
  'independent',
  'ej',
  'civic-service',
];

const roles = getRoles();

const SiteFilters = props => (
  <Filter {...props}>
    <PartialSearchInput source="organisationName" />
    <SelectInput
      source="origin"
      choices={TranslateChoices(origin, 'site.origins')}
    />
    {isDataManager(roles) && <RegionFilter label="Région" source="regionId" />}
  </Filter>
);

export const SiteList = props => (
  <List
    {...props}
    filters={<SiteFilters />}
    actions={<ListActions />}
    exporter={records =>
      exporter(records).then(csv => downloadCSVForExcel(csv, 'site'))
    }
    {...(isShowOnlyRoles(roles) ? { bulkActionButtons: false } : {})}
  >
    <Datagrid
      rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      sort={{ field: 'organisatioName', order: 'ASC' }}
    >
      <TranslateField source="origin" namespace="site.origins" />
      <ReferenceField source="regionId" reference="region">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="organisationName" />
      <AddressField source="address" />
    </Datagrid>
  </List>
);

const SiteForm = (
  <SimpleForm redirect="list">
    <TextInput source="organisationName" validate={[required()]} />
    <SelectInput
      source="origin"
      choices={TranslateChoices(origin, 'site.origins')}
      validate={[required()]}
    />
    {shouldSeeAllRegionsRoles(roles) && (
      <ReferenceInput
        source="regionId"
        reference="region"
        filterToQuery={searchText => ({
          name: { _ilike: `%${searchText}%` },
        })}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name" options={{ autoComplete: 'no' }} />
      </ReferenceInput>
    )}
    <TextInput source="address" validate={[required()]} />
    <TextInput source="postalCode" validate={[required()]} />
    <TextInput source="city" validate={[required()]} />
  </SimpleForm>
);

export const SiteEdit = props => <Edit {...props}>{SiteForm}</Edit>;

export const SiteCreate = props => <Create {...props}>{SiteForm}</Create>;

export const SiteShow = props => (
  <Show {...props} {...additionalShowProps(roles)}>
    <SimpleShowLayout>
      <TranslateField source="origin" namespace="site.origins" />
      <ReferenceField source="regionId" reference="region">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="organisationName" />
      <AddressField source="address" />
    </SimpleShowLayout>
  </Show>
);
