import moment from 'moment';
import 'moment/locale/fr';
import { getCurrentEmail } from 'technical/auth/services';
import sendEmail from '../sendEmail';
import { UserData, queryEmailRegionsAnimationAdd } from './typeDefs';

moment.locale('fr');

const sendMailsRegionsAnimationAdd = async (
  user: UserData,
  regionRattachementId: string,
  regionAddId: string,
) => {
  const payload = {
    regionId: regionRattachementId,
    newRegionId: regionAddId,
    userInfo: `${user.firstName} ${user.lastName}`,
    userMail: user.email,
    adminMail: getCurrentEmail(),
  };

  // Mail au volontaire
  await sendEmail(queryEmailRegionsAnimationAdd, {
    ...payload,
  });
};

export default sendMailsRegionsAnimationAdd;
