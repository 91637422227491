import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import TableChartIcon from '@material-ui/icons/TableChart';
import {
  getRoles,
  isOperator,
  shouldSeeAllRegionsRoles,
} from '../technical/auth/services';

type Resource = {
  name: string;
  icon: string;
  options?: {
    label: string;
  };
  hasList: boolean;
};

const CustomMenu = ({ onMenuClick }) => {
  const roles = getRoles();
  const resources = useSelector<{}, Resource[]>(getResources);
  return (
    <div>
      {resources.map(resource => {
        if (resource.hasList) {
          if (resource.name === 'region' && !shouldSeeAllRegionsRoles(roles)) {
            return null;
          }

          if (resource.name === 'speakerEvaluation') {
            return null;
          }

          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
            />
          );
        }

        return null;
      })}
      {isOperator(roles) && (
        <MenuItemLink
          key="regionExport"
          to="/exports/"
          primaryText="Export des évaluations de la région"
          leftIcon={<TableChartIcon />}
          onClick={onMenuClick}
        />
      )}
    </div>
  );
};

export default CustomMenu;
