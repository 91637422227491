import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useNotify, useRedirect } from 'react-admin';
import config from '../config';
import { getHighestRole, getRoles } from '../technical/auth/services';

const MutationUpdateClass = async function MutationUpdateClass(
  formStateValues,
  packId,
) {
  const [name, level, establishmentId, type, theme, parentsProgram] = [
    formStateValues?.name ?? undefined,
    formStateValues?.level ?? undefined,
    formStateValues?.establishmentId ?? undefined,
    formStateValues?.type ?? undefined,
    formStateValues?.theme ?? undefined,
    formStateValues?.parentsProgram ?? undefined,
  ];

  const query = `mutation MutationUpdateClass(
      $packId: uuid!,
      $name:String,
      $level:String,
      $establishmentId:uuid,
      $type:String,
      $theme:String,
      $parentsProgram:Boolean
      ) {
      update_class(
        _set: {
          name:$name
          level:$level
          establishmentId:$establishmentId
          type:$type
          theme:$theme
          parentsProgram:$parentsProgram
        },
        where: {
          id:{_eq:$packId}
        }
      )
      {
        affected_rows
      }
    }
  `;

  const variables = {
    packId,
    name,
    level,
    establishmentId,
    type,
    theme,
    parentsProgram,
  };

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

const SaveClassButton = props => {
  const formState = useFormState();
  const { redirect, record } = props;
  const packId = record.id;
  const redirectTo = useRedirect();
  const notify = useNotify();

  const handleClick = useCallback(() => {
    if (!formState.valid) {
      const { errors } = formState;
      Object.keys(errors).forEach(
        error => notify(`${errors[error][0]}`, 'warning'),
        [],
      );
      return;
    }

    MutationUpdateClass(formState.values, packId)
      .then(() => {
        notify('Le pack est enregistré');
        redirectTo(redirect);
      })
      .catch(() => {
        notify("Une erreur est survenue lors de l'enregistrement du pack");
      });
  }, [formState, notify, redirectTo, packId, redirect]);

  return redirect === 'edit' ? (
    <SaveButton {...props} label="Enregistrer le pack" />
  ) : (
    <SaveButton
      {...props}
      label="Enregistrer le pack"
      handleSubmitWithRedirect={handleClick}
    />
  );
};

export default SaveClassButton;
