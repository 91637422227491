import papaparse, { ParseResult } from 'papaparse';
import moment from 'moment';
import dataProvider from '../../technical/api/dataProvider';
import { omitUndefinedValues } from '../../technical/service/object';
import {
  importExportLevels,
  importExportTypeClasses,
} from '../../components/exporter';

type Establishment = {
  id: string;
  address: string;
  regionId: string;
};

type Pack = {
  id: string;
  name: string;
  level: string | null;
  type: string;
  establishmentId: string;
};

type Teacher = {
  id: string;
  civility: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type Episode = {
  id: string;
  dateStart: Date | null;
  dateEnd: Date | null;
  classId: string;
  teacherId: string | null;
  classroomNumber: string | null;
};

type Object = {
  pack: {
    name: string;
    type: string;
    level: string | null;
  };
  teacher: {
    civility: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  episodes: {
    dateStart: Date | null;
    dateEnd: Date | null;
    number: number;
    classroomNumber: string | null;
  }[];
};

const parseFile = function parseFile(file: File): Promise<ParseResult<any>> {
  return new Promise((resolve, reject) => {
    papaparse.parse(file, {
      skipEmptyLines: true,
      complete: results => resolve(results),
      error: error => reject(error),
      encoding: 'ISO-8859-1',
    });
  });
};

const csvLineToObject = function csvLineToObject(
  csvLine: string[],
  index,
  notify,
): Object {
  const level = csvLine[0];
  // Check Class Level
  if (!Object.keys(importExportLevels).includes(level)) {
    notify(
      'resources.establishment.error.importPackLevel',
      'warning',
      {
        line: (index + 1).toString(),
        data: level,
      },
      false,
      5000,
    );
    throw new Error(`Invalid level at line ${index + 1} and column A`);
  }
  const name = csvLine[1];
  // Check Class Name
  if (!name || name === '') {
    notify(
      'resources.establishment.error.importPackName',
      'warning',
      {
        line: (index + 1).toString(),
        data: '',
      },
      false,
      5000,
    );
    throw new Error(`Invalid name at line ${index + 1} and column B`);
  }
  const type = csvLine[2];
  // Check Class Type
  if (!Object.keys(importExportTypeClasses).includes(type)) {
    notify(
      'resources.establishment.error.importPackType',
      'warning',
      {
        line: (index + 1).toString(),
        data: type,
      },
      false,
      5000,
    );
    throw new Error(`Invalid class type at line ${index + 1} and column C`);
  }

  return {
    pack: {
      type: importExportTypeClasses[type],
      name: csvLine[1],
      level: importExportLevels[level],
    },
    teacher: {
      civility: csvLine[10],
      firstName: csvLine[11],
      lastName: csvLine[12],
      email: csvLine[13],
      // phone: csvLine[8],
      phone: '',
    },
    episodes: [
      {
        dateStart: csvLine[4]
          ? moment(csvLine[4], 'DD/MM/YYYY HH:mm').toDate()
          : null,
        dateEnd: csvLine[5]
          ? moment(csvLine[5], 'DD/MM/YYYY HH:mm').toDate()
          : null,
        number: 1,
        classroomNumber: csvLine[3],
      },
      {
        dateStart: csvLine[6]
          ? moment(csvLine[6], 'DD/MM/YYYY HH:mm').toDate()
          : null,
        dateEnd: csvLine[7]
          ? moment(csvLine[7], 'DD/MM/YYYY HH:mm').toDate()
          : null,
        number: 2,
        classroomNumber: csvLine[3],
      },
      {
        dateStart: csvLine[8]
          ? moment(csvLine[8], 'DD/MM/YYYY HH:mm').toDate()
          : null,
        dateEnd: csvLine[9]
          ? moment(csvLine[9], 'DD/MM/YYYY HH:mm').toDate()
          : null,
        number: 3,
        classroomNumber: csvLine[3],
      },
    ],
  };
};

// GET_MANY
const insertOrUpdateTeacher = async function insertOrUpdateTeacher(
  teacher: Omit<Teacher, 'id'>,
): Promise<Teacher | null> {
  if (
    teacher &&
    teacher.email &&
    teacher.email.match(
      /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
    )
  ) {
    const contacts = (
      await dataProvider('GET_LIST', 'contact', {
        filter: { email: teacher.email },
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: 'id',
          order: 'DESC',
        },
      })
    ).data;
    const existingContact = contacts.length > 0 ? contacts[0] : undefined;

    if (existingContact) {
      return (
        await dataProvider('UPDATE', 'contact', {
          id: existingContact.id,
          data: omitUndefinedValues(teacher),
          previousData: omitUndefinedValues(existingContact),
        })
      ).data;
    }
    return (
      await dataProvider('CREATE', 'contact', {
        data: omitUndefinedValues(teacher),
      })
    ).data;
  }
  return null;
};

const insertPack = async function insertPack(
  pack: Omit<Pack, 'id'>,
): Promise<Pack> {
  return (
    await dataProvider('CREATE', 'class', { data: omitUndefinedValues(pack) })
  ).data;
};

const insertEpisode = async function insertEpisode(
  episode: Omit<Episode, 'id'>,
): Promise<Episode | void> {
  if (episode.dateStart && episode.dateEnd) {
    return (
      await dataProvider('CREATE', 'episode', {
        data: omitUndefinedValues(episode),
      })
    ).data;
  }
  return Promise.resolve();
};

const importLine = async function importLine(
  line: string[],
  index: number,
  establishment: Establishment,
  notify: any,
): Promise<void> {
  const object = csvLineToObject(line, index, notify);
  const teacher = await insertOrUpdateTeacher(object.teacher);
  const pack = await insertPack({
    ...object.pack,
    establishmentId: establishment.id,
  });
  await Promise.all(
    object.episodes.map(episode =>
      insertEpisode({
        ...episode,
        classId: pack.id,
        teacherId: teacher ? teacher.id : null,
      }),
    ),
  );
};

export default async function importFile(
  file: File,
  establishment: Establishment,
  notify: any,
): Promise<void> {
  const parsedFile = await parseFile(file);
  if (parsedFile.data.length <= 1) {
    throw new Error('Empty csv!');
  }
  parsedFile.data.shift(); // Remove header
  await Promise.all(
    parsedFile.data.map((line, index) =>
      importLine(line, index, establishment, notify),
    ),
  );
}
