import Card from '@material-ui/core/Card';
import { Title } from 'react-admin';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CardContent } from '@material-ui/core';
import { getRegionRattachement } from '../technical/auth/services';
import SpeakerEvalExportButton from '../components/SpeakerEvalExportButton';
import {
  firstDayOfTheYearToString,
  lastDayOfTheYearToString,
} from '../components/DateInput';

interface Props {
  loading?: boolean;
}

function RegionExport({ loading }: Props) {
  const regionRattachement = getRegionRattachement();
  return (
    <Card>
      <Title title="Exports des évaluations de la région" />
      {loading ? (
        <CircularProgress />
      ) : (
        <CardContent>
          <div>
            <SpeakerEvalExportButton
              regionId={regionRattachement}
              dateStart={firstDayOfTheYearToString}
              dateEnd={lastDayOfTheYearToString}
            />
          </div>
          <div>
            <SpeakerEvalExportButton
              regionId={regionRattachement}
              dateEnd={firstDayOfTheYearToString}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
}

export default RegionExport;
