import React from 'react';
import {
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  FunctionField,
  required,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import { exporter, downloadCSVForExcel, ListActions } from '../../components';
import SpeakerEvalExportButton from '../../components/SpeakerEvalExportButton';
import {
  firstDayOfTheYearToString,
  lastDayOfTheYearToString,
} from '../../components/DateInput';

export const RegionList = (props: any) => (
  <List
    {...props}
    exporter={records =>
      exporter(records).then(csv => downloadCSVForExcel(csv, 'regions'))
    }
    actions={<ListActions />}
  >
    <Datagrid
      rowClick="edit"
      sort={{ field: 'name', order: 'ASC' }}
      expand={<RegionExpand />}
    >
      <TextField source="name" />
      <ReferenceField source="managerId" reference="operator">
        <FunctionField
          label="Name"
          render={(record: any) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <SpeakerEvalExportButton
        dateStart={firstDayOfTheYearToString}
        dateEnd={lastDayOfTheYearToString}
      />
    </Datagrid>
  </List>
);

const RegionExpand = props => {
  const { record, ...rest } = props;

  return (
    <Show {...rest} title=" ">
      <SimpleShowLayout>
        <SpeakerEvalExportButton dateEnd={firstDayOfTheYearToString} />
      </SimpleShowLayout>
    </Show>
  );
};

export const RegionEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput
          source="managerId"
          reference="operator"
          filterToQuery={() => ({
            _and: [
              {
                _or: [
                  {
                    type: {
                      _eq: 'operator',
                    },
                  },
                  {
                    type: {
                      _eq: 'administrator',
                    },
                  },
                ],
              },
              {
                regionId: {
                  _eq: props.id,
                },
              },
            ],
          })}
        >
          <SelectInput
            optionText={(user: any) => `${user.firstName} ${user.lastName}`}
            validate={required()}
            allowEmpty
            emptyText=""
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export const RegionCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};
