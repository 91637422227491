import Button from '@material-ui/core/Button';
import { Login } from 'ra-ui-materialui';
import React from 'react';
import { useLogin } from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import createMyTheme from './Theme/createMyTheme';

interface Props {
  loading?: boolean;
  signupMode?: boolean;
}

const themeLogin = createMyTheme({
  palette: {
    primary: {
      main: '#fa8c16',
    },
  },
});
function LoginPage({ loading, signupMode }: Props) {
  const login = useLogin();
  const styles: any = {};
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      styles.backgroundColor = '#FEC800';
    } else {
      styles.backgroundColor = '#00fe90';
    }
  }
  return (
    <Login className="custom-login">
      <img
        src="/AppLogo.svg"
        alt="Logo EJ"
        style={{
          width: '100%',
          maxWidth: '300px',
          padding: '30px',
          paddingBottom: '60px',
        }}
      />
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <ThemeProvider theme={themeLogin}>
            <Button
              variant="contained"
              color="primary"
              style={styles}
              onClick={() => login({ signupMode })}
            >
              {signupMode
                ? 'Créer mon compte Admin Maestro'
                : "Connexion à l'Admin Maestro"}
            </Button>
          </ThemeProvider>
        )}
      </div>
    </Login>
  );
}

export default LoginPage;
