import moment from 'moment';
import 'moment/locale/fr';
import { getCurrentEmail } from 'technical/auth/services';
import sendEmail from '../sendEmail';
import { UserData, queryEmailRegionRattachementUpdate } from './typeDefs';

moment.locale('fr');

const sendMailsRegionRattachementUpdate = async (
  user: UserData,
  newRegionRattachementId: string,
  oldRegionRattachementId: string,
) => {
  const payload = {
    userEmail: user.email,
    userInfo: `${user.firstName} ${user.lastName}`,
    newRegionId: newRegionRattachementId,
    oldRegionId: oldRegionRattachementId,
    adminEmail: getCurrentEmail(),
  };

  // Mail au volontaire
  await sendEmail(queryEmailRegionRattachementUpdate, {
    ...payload,
  });
};

export default sendMailsRegionRattachementUpdate;
