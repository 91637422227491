import React from 'react';
import {
  AutocompleteInput,
  Button,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FunctionField,
  Link,
  List,
  Pagination,
  ReferenceField,
  ReferenceFieldController,
  ReferenceInput,
  ReferenceManyField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useQuery,
  SelectInput,
  required,
  NullableBooleanInput,
} from 'react-admin';
import { stringify } from 'querystring';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  downloadCSVForExcel,
  DateTimeInput,
  DateInput,
  NameAddressField,
  ReferenceSearchInput,
  PartialSearchInput,
} from 'components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  additionalShowProps,
  getRoles,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from 'technical/auth/services';
import exporter from './exporter';
import ListActions from '../../components/ListActions';
import RegionFilter from '../../components/RegionFilter';
import {
  firstDayOfTheYearToString,
  lastDayOfTheYearToString,
} from '../../components/DateInput';

const validateDateTimesInput = (value, allValues) => {
  const dateEnd = value && typeof value === 'string' ? new Date(value) : value;
  return dateEnd >= new Date(allValues.dateStart)
    ? undefined
    : 'La date de fin doit être après la date de début';
};

const roles = getRoles();
const ConditionalQuerySpeakerEvaluation = record => {
  return useQuery({
    type: 'getList',
    resource: 'speakerEvaluation',
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'episode_id', order: 'desc' },
      filter: { episode_id: record?.id },
    },
  });
};

const ConditionSpeakerEvaluationField = record => {
  const { data } = ConditionalQuerySpeakerEvaluation(record?.record);
  if (data && data.length >= 1) {
    return (
      <Button
        component={Link}
        to={{
          pathname: `/speakerEvaluation`,
          search: stringify({
            filter: JSON.stringify({
              episode_id: record?.record?.id,
            }),
            sort: 'intervenant.lastName',
            order: 'ASC',
          }),
        }}
        label="resources.episode.actions.seeSpeakerEvaluations"
        onClick={e => e.stopPropagation()}
      />
    );
  }
  return <></>;
};

export const EpisodeDataGrid = props => {
  return (
    <Datagrid rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'} {...props}>
      <ReferenceFieldController
        addLabel
        source="classId"
        reference="class"
        label="Etablissement"
        link={false}
      >
        {({ referenceRecord }) => (
          <ReferenceField
            basePath="/class"
            resource="class"
            record={referenceRecord || {}}
            source="establishmentId"
            reference="establishment"
            link={(record, reference) =>
              `/${reference}/${record.establishmentId}`
            }
          >
            <NameAddressField source="address" />
          </ReferenceField>
        )}
      </ReferenceFieldController>
      <ReferenceField source="teacherId" reference="contact" label="Enseignant">
        <FunctionField
          label="Name"
          render={contact => `${contact.firstName} ${contact.lastName}`}
        />
      </ReferenceField>
      <TextField source="classroomNumber" />
      <DateField source="dateStart" showTime />
      <DateField source="dateEnd" showTime />
      <ReferenceField source="classId" reference="class">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="number" />
      <ReferenceField
        source="expertId"
        reference="animator"
        link={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <FunctionField
          label="Name"
          render={contact => `${contact.firstName} ${contact.lastName}`}
        />
      </ReferenceField>
      <ReferenceField
        source="firstAnimateurId"
        reference="animator"
        link={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <FunctionField
          label="Name"
          render={contact => `${contact.firstName} ${contact.lastName}`}
        />
      </ReferenceField>
      <ReferenceField
        source="secondAnimateurId"
        reference="animator"
        link={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <FunctionField
          label="Name"
          render={contact => `${contact.firstName} ${contact.lastName}`}
        />
      </ReferenceField>
      {/* <TextField source="firstGuest" />
      <TextField source="secondGuest" /> */}
      <ConditionSpeakerEvaluationField />
      <FunctionField
        label="Expert externe"
        // eslint-disable-next-line consistent-return
        render={record => {
          if (record.expertexterne) {
            return 'E';
          }
        }}
      />
    </Datagrid>
  );
};

const EpisodeListExpand = props => {
  const { record, ...rest } = props;
  const { id, dateStart, dateEnd } = record;
  return (
    <Show
      {...rest}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ReferenceManyField
          pagination={<Pagination />}
          sort={{ field: 'createdAt', order: 'DESC' }}
          filter={{
            _and: [
              {
                _or: [
                  {
                    episodesByExpertid: {
                      class: {
                        establishment: {
                          classes: {
                            episodes: {
                              id: { _eq: id },
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    episodesByFirstanimateurid: {
                      class: {
                        establishment: {
                          classes: {
                            episodes: {
                              id: { _eq: id },
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    episodesBySecondanimateurid: {
                      class: {
                        establishment: {
                          classes: {
                            episodes: {
                              id: { _eq: id },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
              {
                user_type: {
                  value: {
                    _in: ['animator', 'expert'],
                  },
                },
              },
              {
                _and: [
                  {
                    _or: [
                      { _not: { episodesByExpertid: {} } },
                      {
                        episodesByExpertid: {
                          _or: [
                            {
                              dateEnd: { _lt: dateStart },
                            },
                            {
                              dateStart: { _gt: dateEnd },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    _or: [
                      { _not: { episodesByFirstanimateurid: {} } },
                      {
                        episodesByFirstanimateurid: {
                          _or: [
                            {
                              dateEnd: { _lt: dateStart },
                            },
                            {
                              dateStart: { _gt: dateEnd },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    _or: [
                      { _not: { episodesBySecondanimateurid: {} } },
                      {
                        episodesBySecondanimateurid: {
                          _or: [
                            {
                              dateEnd: { _lt: dateStart },
                            },
                            {
                              dateStart: { _gt: dateEnd },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          source="episode"
          reference="animator"
          label="Volontaires disponibles"
        >
          <Datagrid>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="email" />
            <TextField source="phone" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

const filterToQueryVolunteer = searchText => ({
  _and: [
    {
      type: { _in: ['animator', 'expert'] },
      isActive: { _eq: true },
      _or: [
        { firstName: { _ilike: `%${searchText}%` } },
        { lastName: { _ilike: `%${searchText}%` } },
      ],
    },
  ],
});

const optionText = choice =>
  choice && choice.firstName && choice.lastName
    ? `${choice.firstName} ${choice.lastName}`
    : `Aucun animateur`;

const EpisodeFilters = props => {
  return (
    <Filter {...props}>
      <PartialSearchInput label="Nom du pack" source="class.name" />
      <DateInput
        fixedHour={0}
        fixedMinute={1}
        label="Du"
        source="dateStart._gt"
        alwaysOn
      />
      <DateInput
        fixedHour={23}
        fixedMinute={59}
        label="Au"
        source="dateStart._lt"
        alwaysOn
      />
      {shouldSeeAllRegionsRoles(roles) && (
        <RegionFilter label="Région" source="class.establishment.regionId" />
      )}
      <ReferenceInput
        source="class.establishmentId"
        reference="class"
        label="resources.class.fields.establishmentId"
      >
        <ReferenceSearchInput
          source="name"
          reference="establishment"
          label="resources.class.fields.establishmentId"
          filterToQuery={searchText => ({
            _or: [{ name: { _ilike: `%${searchText}%` } }],
          })}
          optionText={e =>
            e.name ? `${e.name} - ${e.address} ${e.postalCode}, ${e.city}` : ''
          }
          perPage={100}
          shouldRenderSuggestions={value => value.length > 0}
        />
      </ReferenceInput>
      <PartialSearchInput label="Ville" source="class.establishment.city" />
      <PartialSearchInput
        label="Code postal"
        source="class.establishment.postalCode"
      />
      <ReferenceSearchInput
        source="teacherId"
        reference="contact"
        filterToQuery={searchText => ({
          _or: [
            { firstName: { _ilike: `%${searchText}%` } },
            { lastName: { _ilike: `%${searchText}%` } },
          ],
        })}
        optionText={choice =>
          choice.firstName || choice.lastName
            ? `${choice.firstName} ${choice.lastName}`
            : ''
        }
      />
      <ReferenceSearchInput
        source="expertId"
        reference="animator"
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        optionText={optionText}
        shouldRenderSuggestions={value => value.length > 0}
      />
      <ReferenceSearchInput
        source="firstAnimateurId"
        reference="animator"
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        optionText={optionText}
        shouldRenderSuggestions={value => value.length > 0}
      />
      <ReferenceSearchInput
        source="secondAnimateurId"
        reference="animator"
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        optionText={optionText}
        shouldRenderSuggestions={value => value.length > 0}
      />
      <NullableBooleanInput source="expertexterne" />
      {/* <PartialSearchInput label="Premier invité" source="firstGuest" />
      <PartialSearchInput label="Second invité" source="secondGuest" /> */}
    </Filter>
  );
};
const EpisodeToPlanFilters = props => {
  return (
    <Filter {...props}>
      <PartialSearchInput label="Nom du pack" source="class.name" />
      <DateInput
        fixedHour={0}
        fixedMinute={1}
        label="Du"
        source="updatedAt._gt"
        alwaysOn
      />
      <DateInput
        fixedHour={23}
        fixedMinute={59}
        label="Au"
        source="updatedAt._lt"
        alwaysOn
      />
      {shouldSeeAllRegionsRoles(roles) && (
        <RegionFilter label="Région" source="class.establishment.regionId" />
      )}
      <ReferenceInput
        source="class.establishmentId"
        reference="class"
        label="resources.class.fields.establishmentId"
      >
        <ReferenceSearchInput
          source="name"
          reference="establishment"
          label="resources.class.fields.establishmentId"
          filterToQuery={searchText => ({
            _or: [{ name: { _ilike: `%${searchText}%` } }],
          })}
          optionText={e =>
            e.name ? `${e.name} - ${e.address} ${e.postalCode}, ${e.city}` : ''
          }
          perPage={100}
          shouldRenderSuggestions={value => value.length > 0}
        />
      </ReferenceInput>
      <PartialSearchInput label="Ville" source="class.establishment.city" />
      <PartialSearchInput
        label="Code postal"
        source="class.establishment.postalCode"
      />
      <ReferenceSearchInput
        source="teacherId"
        reference="contact"
        filterToQuery={searchText => ({
          _or: [
            { firstName: { _ilike: `%${searchText}%` } },
            { lastName: { _ilike: `%${searchText}%` } },
          ],
        })}
        optionText={choice =>
          choice.firstName || choice.lastName
            ? `${choice.firstName} ${choice.lastName}`
            : ''
        }
      />
      <ReferenceSearchInput
        source="expertId"
        reference="animator"
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        optionText={optionText}
        shouldRenderSuggestions={value => value.length > 0}
      />
      <ReferenceSearchInput
        source="firstAnimateurId"
        reference="animator"
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        optionText={optionText}
        shouldRenderSuggestions={value => value.length > 0}
      />
      <ReferenceSearchInput
        source="secondAnimateurId"
        reference="animator"
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        optionText={optionText}
        shouldRenderSuggestions={value => value.length > 0}
      />
      <NullableBooleanInput source="expertexterne" />
      {/* <PartialSearchInput label="Premier invité" source="firstGuest" />
      <PartialSearchInput label="Second invité" source="secondGuest" /> */}
    </Filter>
  );
};

const EpisodeToFillFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="class.establishmentId"
      reference="class"
      label="resources.class.fields.establishmentId"
    >
      <ReferenceSearchInput
        source="name"
        reference="establishment"
        label="resources.class.fields.establishmentId"
        filterToQuery={searchText => ({
          _or: [{ name: { _ilike: `%${searchText}%` } }],
        })}
        optionText={e =>
          e.name ? `${e.name} - ${e.address} ${e.postalCode}, ${e.city}` : ''
        }
        perPage={100}
        shouldRenderSuggestions={value => value.length > 0}
      />
    </ReferenceInput>
    <DateInput
      fixedHour={0}
      fixedMinute={1}
      label="Du"
      source="dateStart._gt"
      alwaysOn
    />
    <DateInput
      fixedHour={23}
      fixedMinute={59}
      label="Au"
      source="dateStart._lt"
      alwaysOn
    />
    {shouldSeeAllRegionsRoles(roles) && (
      <RegionFilter label="Région" source="class.establishment.regionId" />
    )}
  </Filter>
);

const chooseFilters = resource => {
  if (resource === 'episode_to_plan') {
    return <EpisodeToPlanFilters />;
  }
  if (resource === 'episode_to_fill') {
    return <EpisodeToFillFilter />;
  }
  return <EpisodeFilters />;
};

export const EpisodeList = props => {
  const { resource } = props;
  const sort = () => {
    if (resource === 'episode_to_plan') {
      return { field: 'class.name', order: 'ASC' };
    }
    return { field: 'dateStart', order: 'ASC' };
  };

  const filter = () => {
    if (resource === 'episode_to_fill') {
      return {
        _and: [
          {
            _or: [
              { firstAnimateurId: { _is_null: true } },
              { expertId: { _is_null: true } },
            ],
          },
          {
            dateStart: {
              _gt: moment()
                .minute(1)
                .hour(0)
                .toISOString(),
            },
          },
        ],
      };
    }
    if (resource === 'episode_to_plan') {
      return {
        _or: [
          { dateStart: { _is_null: true } },
          { dateEnd: { _is_null: true } },
        ],
      };
    }
    return {};
  };

  return (
    <List
      {...props}
      filters={chooseFilters(resource)}
      filterDefaultValues={
        resource !== 'episode_to_plan'
          ? {
              dateStart: {
                _gt: moment()
                  .hour(0)
                  .minute(0)
                  .toISOString(),
                _lt: moment()
                  .add(1, 'months')
                  .hour(23)
                  .minute(59)
                  .toISOString(),
              },
            }
          : {
              updatedAt: {
                _gt: firstDayOfTheYearToString,
                _lt: lastDayOfTheYearToString,
              },
            }
      }
      filter={filter()}
      sort={sort()}
      exporter={records =>
        exporter(records).then(csv => downloadCSVForExcel(csv, 'episodes'))
      }
      actions={<ListActions />}
      {...(isShowOnlyRoles(roles) ? { bulkActionButtons: false } : {})}
    >
      {resource === 'episode_to_fill' ? (
        <EpisodeDataGrid expand={<EpisodeListExpand />} />
      ) : (
        <EpisodeDataGrid />
      )}
    </List>
  );
};
const useStyles = makeStyles({
  root: {
    width: '256px',
  },
});

const DateTimeStartInput = () => {
  const form = useForm();
  const classes = useStyles();
  return (
    <DateTimeInput
      label="Date de début"
      source="dateStart"
      onChange={value => {
        form.change('dateEnd', value);
      }}
      className={classes.root}
    />
  );
};

const EpisodeForm = props => {
  const history = useHistory();
  return (
    <SimpleForm {...props} redirect={() => history.goBack()}>
      <ReferenceInput
        source="classId"
        reference="class"
        filterToQuery={searchText => ({
          _or: [{ name: { _ilike: `%${searchText}%` } }],
        })}
      >
        <AutocompleteInput optionText="name" allowEmpty />
      </ReferenceInput>
      <DateTimeStartInput />
      <DateTimeInput
        label="Date de fin"
        source="dateEnd"
        validate={validateDateTimesInput}
      />
      <ReferenceInput
        source="teacherId"
        reference="contact"
        allowEmpty
        filterToQuery={searchText => ({
          _or: [
            { firstName: { _ilike: `%${searchText}%` } },
            { lastName: { _ilike: `%${searchText}%` } },
          ],
        })}
      >
        <AutocompleteInput
          optionText={choice =>
            choice && choice.firstName && choice.lastName
              ? `${choice.firstName} ${choice.lastName}`
              : 'Aucun enseignant'
          }
        />
      </ReferenceInput>
      <TextInput source="classroomNumber" />
      <ReferenceInput
        source="expertId"
        reference="animator"
        allowEmpty
        filterToQuery={filterToQueryVolunteer}
        perPage={100}
        sort={{ field: 'lastName', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText={optionText}
          shouldRenderSuggestions={value => value.length > 0}
        />
      </ReferenceInput>
      <ReferenceInput
        source="firstAnimateurId"
        reference="animator"
        allowEmpty
        perPage={100}
        sort={{ field: 'lastName', order: 'ASC' }}
        shouldRenderSuggestions={value => value.length > 0}
        filterToQuery={filterToQueryVolunteer}
      >
        <AutocompleteInput optionText={optionText} />
      </ReferenceInput>
      <ReferenceInput
        source="secondAnimateurId"
        reference="animator"
        allowEmpty
        perPage={100}
        sort={{ field: 'lastName', order: 'ASC' }}
        shouldRenderSuggestions={value => value.length > 0}
        filterToQuery={filterToQueryVolunteer}
      >
        <AutocompleteInput optionText={optionText} />
      </ReferenceInput>
      <TextInput source="firstGuest" />
      <TextInput source="secondGuest" />
      <SelectInput
        validate={[required()]}
        required
        source="number"
        choices={[
          { id: 1, name: '1' },
          { id: 2, name: '2' },
          { id: 3, name: '3' },
        ]}
      />
    </SimpleForm>
  );
};

export const EpisodeEdit = (props: any) => {
  return (
    <Edit {...props}>
      <EpisodeForm />
    </Edit>
  );
};

export const EpisodeCreate = (props: any) => (
  <Create {...props}>
    <EpisodeForm />
  </Create>
);

export const EpisodeShow = (props: any) => (
  <Show {...props} {...additionalShowProps(roles)}>
    <SimpleShowLayout>
      {/* Classe */}
      <ReferenceField label="class" source="classId" reference="class">
        <FunctionField
          render={classData => `${classData.level} ${classData.name}`}
        />
      </ReferenceField>
      <DateField source="dateStart" showTime />
      <DateField source="dateEnd" showTime />
      {/* Premier Animateur */}
      <ReferenceField
        label="firstAnimateur"
        source="firstAnimateurId"
        reference="animator"
        link={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <FunctionField
          render={animator => `${animator.firstName} ${animator.lastName}`}
        />
      </ReferenceField>
      {/* Deuxième Animateur */}
      <ReferenceField
        label="secondAnimateur"
        source="secondAnimateurId"
        reference="animator"
        link={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <FunctionField
          render={animator => `${animator.firstName} ${animator.lastName}`}
        />
      </ReferenceField>
      {/* Expert */}
      <ReferenceField
        label="expert"
        source="expertId"
        reference="animator"
        link={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <FunctionField
          render={animator => `${animator.firstName} ${animator.lastName}`}
        />
      </ReferenceField>
      <TextField source="id" />
      <TextField source="number" />
    </SimpleShowLayout>
  </Show>
);
