import React, { useState, useEffect } from 'react';
import {
  AutocompleteInput,
  Button,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  // FunctionField,
  List,
  ReferenceField,
  BooleanField,
  BooleanInput,
  ReferenceFieldController,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  TabbedForm,
  FormTab,
  SimpleShowLayout,
  TextField,
  TextInput,
  useNotify,
  required,
  Toolbar,
  BulkDeleteButton,
  useDataProvider,
  useRefresh,
  FunctionField,
} from 'react-admin';
import translation from 'translation/fr';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import exporter from './exporter';
import { EpisodeDataGrid } from '../episode/resource';
import PartialSearchInput from '../../components/PartialSearchInput';
import ReferenceSearchInput from '../../components/ReferenceSearchInput';
import TranslateChoices from '../../components/TranslateChoices';
import ListActions from '../../components/ListActions';
import {
  additionalShowProps,
  getHighestRole,
  getRoles,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from '../../technical/auth/services';
import TranslateField from '../../components/translateField';
import { address, NameAddressField } from '../../components/NameAddressField';
import { downloadCSVForExcel } from '../../components/exporter';
import BulkPublishButton from './BulkPublishButton';
import SaveMultipleEpisodesButton from '../../components/SaveMultipleEpisodesButton';
import EpisodesFormInputs from '../../components/EpisodesFormInputs';
import config from '../../config';
import SaveClassButton from '../../components/SaveClassButton';
import RegionFilter from '../../components/RegionFilter';
import { DateInput } from '../../components';
import {
  firstDayOfTheYearToString,
  lastDayOfTheYearToString,
} from '../../components/DateInput';

const themeChoices = TranslateChoices(
  ['T1', 'T2', 'T3a', 'T3b'],
  'resources.class.answers',
);
const level = ['CM1', 'CM2', '6', '5', '4', '3', 'highschool'];

const typeClass = [
  'classical',
  'segpa',
  'job_prepa',
  'second_pro',
  'cap',
  'ulis',
  'upe2a',
  // 'cap_orientation',
];

const roles = getRoles();
const ClassListExpand = props => {
  const { record, ...rest } = props;
  return (
    <Show
      {...rest}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ReferenceManyField
          sort={{ field: 'dateStart', order: 'DESC' }}
          filter={{ classId: record.id }}
          source="class"
          reference="episode"
          target="classId"
          link="edit"
          label="resources.class.fields.episodes"
        >
          <EpisodeDataGrid />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

const useStyles = makeStyles({
  containedPrimary: {
    backgroundColor: '#f48b39',
    color: 'white',
    '&:hover': {
      backgroundColor: '#aa6127',
      color: 'white',
    },
  },
});

const CreateEpisodeButton = props => {
  const { record } = props;
  const classes = useStyles();
  return (
    <Button
      component={Link}
      to={{
        pathname: '/episode/create',
        state: record ? { record: { classId: record.id } } : undefined,
      }}
      className={classes.containedPrimary}
      variant="contained"
      label="resources.class.actions.add_episode"
      onClick={e => e.stopPropagation()}
    />
  );
};

const PublishClassButton = props => {
  const { record } = props;
  const [loading, setLoading] = React.useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const publish = async () => {
    setLoading(true);
    try {
      await dataProvider.update('class', {
        id: record.id,
        data: { publicationDate: record.publicationDate ? null : new Date() },
      });
      refresh();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notify("Une erreur est survenue, le pack n'a pas été publié", 'warning');
    }
  };
  return (
    <Button
      label={
        record.publicationDate
          ? 'resources.class.actions.unpublish'
          : 'resources.class.actions.publish'
      }
      onClick={e => {
        e.stopPropagation();
        publish();
      }}
      disabled={loading}
    />
  );
};

const publicationChoices = [
  { id: false, name: 'Publié' },
  { id: true, name: 'Non publié' },
];

const ClassFilters = props => {
  return (
    <Filter {...props}>
      <PartialSearchInput source="name" />
      <SelectInput
        source="level"
        choices={TranslateChoices(level, 'resources.class.levels')}
      />
      <ReferenceSearchInput
        source="establishmentId"
        reference="establishment"
        filterToQuery={searchText => ({
          _or: [{ name: { _ilike: `%${searchText}%` } }],
        })}
        optionText={address}
      />
      <PartialSearchInput label="Ville" source="establishment.city" />
      <PartialSearchInput
        label="Code postal"
        source="establishment.postalCode"
      />
      <SelectInput
        source="publicationDate._is_null"
        label="Publication"
        choices={publicationChoices}
      />
      <DateInput
        fixedHour={0}
        fixedMinute={1}
        label="Du"
        source="episodes.dateStart._gt"
        alwaysOn
      />
      <DateInput
        fixedHour={23}
        fixedMinute={59}
        label="Au"
        source="episodes.dateStart._lt"
        alwaysOn
      />
      {shouldSeeAllRegionsRoles(roles) && (
        <RegionFilter label="Région" source="establishment.regionId" />
      )}
    </Filter>
  );
};

const ClassBulkActionButtons = props => (
  <>
    <BulkPublishButton {...props} />
    <BulkDeleteButton {...props} />
  </>
);
export const ClassList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ClassFilters />}
      filterDefaultValues={{
        episodes: {
          dateStart: {
            _gt: firstDayOfTheYearToString,
            _lt: lastDayOfTheYearToString,
          },
        },
      }}
      exporter={records =>
        exporter(records).then(csv => downloadCSVForExcel(csv, 'class'))
      }
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={
        isShowOnlyRoles(roles) ? false : <ClassBulkActionButtons />
      }
      actions={<ListActions />}
    >
      <Datagrid
        expand={<ClassListExpand />}
        rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'}
      >
        <ReferenceField source="establishmentId" reference="establishment">
          <NameAddressField source="address" />
        </ReferenceField>
        <TranslateField source="level" namespace="resources.class.levels" />
        <TranslateField source="type" namespace="resources.class.types" />
        <TextField source="name" />
        <DateField source="publicationDate" showTime />
        <BooleanField source="parentsProgram" />
        {!isShowOnlyRoles(roles) && [
          <CreateEpisodeButton key="0" />,
          <PublishClassButton key="1" />,
        ]}
        {shouldSeeAllRegionsRoles(roles) && (
          <ReferenceFieldController
            label="resources.establishment.fields.regionId"
            reference="establishment"
            source="establishmentId"
            link={false}
          >
            {({ referenceRecord }) => (
              <ReferenceField
                basePath="/establishment"
                resource="establishment"
                reference="region"
                source="regionId"
                record={referenceRecord || {}}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            )}
          </ReferenceFieldController>
        )}
        <FunctionField
          label="Expert externe"
          // eslint-disable-next-line consistent-return
          render={record => {
            if (record.expertexterne) {
              return 'E';
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

type Episode = {
  id: string | null;
  dateStart: string | null;
  dateEnd: string | null;
  expertId: string | null;
  firstAnimateurId: string | null;
  secondAnimateurId: string | null;
};
const UpdateEpisodesToolbar = props => {
  const { episodesIds, redirect, episodes, ...otherProps } = props;
  return (
    <Toolbar {...otherProps}>
      {!window.location.pathname.includes('episodes') && (
        <SaveClassButton redirect={redirect} />
      )}
      {window.location.pathname.includes('episodes') && (
        <SaveMultipleEpisodesButton
          episodesIds={episodesIds}
          episodes={episodes}
        />
      )}
    </Toolbar>
  );
};

const validateEpisodesForm = values => {
  const errors: any = {};

  function setTimeFieldToToday(field) {
    const today = new Date();

    return new Date(field).setFullYear(
      today.getFullYear(),
      today.getMonth(),
      today.getDay(),
    );
  }
  const timeEndEp1 =
    values.timeEndEp1 && typeof values.timeEndEp1 === 'string'
      ? setTimeFieldToToday(values.timeEndEp1)
      : values.timeEndEp1;
  const timeStartEp1 =
    values.timeStartEp1 && typeof values.timeStartEp1 === 'string'
      ? setTimeFieldToToday(values.timeStartEp1)
      : values.timeStartEp1;
  if (timeEndEp1 < timeStartEp1) {
    errors.timeEndEp1 = [
      "L'heure de fin de l'épisode 1 doit être après l'heure de début",
    ];
  }
  const timeEndEp2 =
    values.timeEndEp2 && typeof values.timeEndEp2 === 'string'
      ? setTimeFieldToToday(values.timeEndEp2)
      : values.timeEndEp2;
  const timeStartEp2 =
    values.timeStartEp2 && typeof values.timeStartEp2 === 'string'
      ? setTimeFieldToToday(values.timeStartEp2)
      : values.timeStartEp2;
  if (timeEndEp2 < timeStartEp2) {
    errors.timeEndEp2 = [
      "L'heure de fin de l'épisode 2 doit être après l'heure de début",
    ];
  }
  const timeEndEp3 =
    values.timeEndEp3 && typeof values.timeEndEp3 === 'string'
      ? setTimeFieldToToday(values.timeEndEp3)
      : values.timeEndEp3;
  const timeStartEp3 =
    values.timeStartEp3 && typeof values.timeStartEp3 === 'string'
      ? setTimeFieldToToday(values.timeStartEp3)
      : values.timeStartEp3;
  if (timeEndEp3 < timeStartEp3) {
    errors.timeEndEp3 = [
      "L'heure de fin de l'épisode 3 doit être après l'heure de début",
    ];
  }
  return errors;
};

const ClassForm = props => {
  const { record } = props;
  const [episodes, setEpisodes] = useState([]);
  React.useEffect(() => {
    const queryGetEpisodes = `query getEpisodes($packId : uuid){
    episode(where: {classId: {_eq:$packId}},order_by: {dateStart: asc}) {
    id
    number
    dateStart
    dateEnd
    expertId
    firstAnimateurId
    secondAnimateurId
    teacherId
    classroomNumber
  }
  }`;

    const fetchEpisodes = async () => {
      const variables = { packId: record.id };
      if (record?.id) {
        const response = await fetch(`${config.graphqlUri}/v1/graphql`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-Hasura-Role': getHighestRole(getRoles()),
          },
          body: JSON.stringify({
            query: queryGetEpisodes,
            variables,
          }),
        });
        const { data } = await response.json();
        const episodeOrdered = data.episode.sort((a, b) => a.number - b.number);
        setEpisodes(episodeOrdered);
      }
    };
    fetchEpisodes();
  }, [record]);
  const episodesIds = episodes.map((value: Episode) => {
    return value.id;
  });

  return (
    <TabbedForm
      validate={validateEpisodesForm}
      toolbar={
        <UpdateEpisodesToolbar episodesIds={episodesIds} episodes={episodes} />
      }
      {...props}
    >
      <FormTab label="Pack">
        <TextField source="id" />
        <ReferenceInput
          source="establishmentId"
          reference="establishment"
          filterToQuery={searchText => ({
            _or: [{ name: { _ilike: `%${searchText}%` } }],
            _and: [{ isActive: { _eq: true } }],
          })}
        >
          <AutocompleteInput optionText={address} allowEmpty />
        </ReferenceInput>
        <TextInput source="name" />
        <SelectInput
          source="level"
          choices={TranslateChoices(level, 'resources.class.levels')}
        />
        <SelectInput
          source="type"
          validate={required()}
          choices={TranslateChoices(typeClass, 'resources.class.types')}
        />
        {!isEmpty(record) && (
          <SelectInput
            source="theme"
            choices={themeChoices}
            defaultValue={themeChoices[0].id}
          />
        )}
        <BooleanInput source="parentsProgram" />
      </FormTab>
      {record?.id && (
        <FormTab label="Episodes" path="episodes">
          <EpisodesFormInputs episodes={episodes} />
        </FormTab>
      )}
    </TabbedForm>
  );
};

const EditTitle = props => {
  const dataProvider = useDataProvider();
  const { record } = props;
  const [establishment, setEstablishment] = useState({ name: '' });
  useEffect(() => {
    dataProvider
      .getOne('establishment', { id: record.establishmentId })
      .then(({ data }) => {
        setEstablishment(data);
      });
  }, [record, dataProvider]);
  return (
    <p>
      {translation.resources.class.levels[record.level]} {record.name} -{' '}
      {establishment.name}
    </p>
  );
};

export const ClassEdit = (props: any) => {
  return (
    <Edit title={<EditTitle />} {...props}>
      <ClassForm />
    </Edit>
  );
};

export const ClassCreate = (props: any) => (
  <Create {...props}>
    <ClassForm redirect="edit" />
  </Create>
);

export const ClassShow = props => {
  return (
    <Show {...props} {...additionalShowProps(roles)}>
      <SimpleShowLayout>
        <ReferenceField
          label="resources.establishment.name"
          source="establishmentId"
          reference="establishment"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="id" />
        {/* <ReferenceField source="establishmentId" reference="establishment">
          <TextField source="classroomNumber" />
        </ReferenceField> */}
      </SimpleShowLayout>
    </Show>
  );
};
