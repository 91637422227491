export const ROLES = {
  'region-manager': 'region-manager',
  national: 'national',
  'data-manager': 'data-manager',
  operator: 'operator',
  user: 'user',
};

export const ALLOWED_ROLES = [
  ROLES['data-manager'],
  ROLES['region-manager'],
  ROLES.national,
  ROLES.operator,
];
