import { WebAuth, AuthorizeOptions } from 'auth0-js';
import logger from 'technical/logger';
import config from '../../config';
import { getRoles, isAllowed, getAuthResult } from './services';

export const webAuth = new WebAuth({
  ...config.auth0,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

// Typed as any as per Auth0 types
function persistAuth(authResult: any) {
  localStorage.setItem('authResult', JSON.stringify(authResult));
  localStorage.setItem('token', authResult.idToken);
}

function unpersistAuth() {
  localStorage.removeItem('token');
  localStorage.removeItem('authResult');
}

export function renewToken() {
  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, function onCheckSession(auth0error, authResult) {
      if (auth0error) {
        logger.error(auth0error);
        return reject(auth0error);
      }

      persistAuth(authResult);
      return resolve(authResult);
    });
  });
}

const authProvider = {
  login: ({ signupMode }: { signupMode: boolean }) => {
    // handle auth0 authentication
    const options: AuthorizeOptions = {
      mode: 'login',
      // if prompt === 'login', force reprompt, else, try to silently reconnect
      prompt: 'login',
    };

    if (signupMode) {
      // @ts-ignore https://community.auth0.com/t/how-to-change-initial-screen-to-sign-up-using-auth0-spa-js-universal-login/32941/7
      options.screen_hint = 'signup';
    }
    webAuth.authorize(options);
    return Promise.reject();
  },

  logout: () => {
    unpersistAuth();
    return Promise.resolve();
  },

  checkError: (error: any) => {
    logger.warn('Error', error.status, error.body);

    if (error.status === 400 && error.body.code === 'invalid-jwt') {
      return renewToken();
    }

    // Resolving as it is not an authentication error
    return Promise.resolve();
  },

  checkAuth: () => {
    const roles = getRoles();
    const authResult = getAuthResult();
    const isActive =
      authResult?.idTokenPayload['https://hasura.io/jwt/claims'][
        'x-hasura-active'
      ] === 'true';

    if (!isAllowed(roles)) {
      return Promise.reject(
        new Error("Vous n'avez pas le droit d'accéder à cette application"),
      );
    }

    if (!isActive) {
      window.location.href = '/deactivate';
      return Promise.reject(
        new Error(
          "Vous ne pouvez pas accéder à l'admin de maestro car votre compte est désactivé. Veillez contacter un administrateur pour plus d'informations",
        ),
      );
    }

    return Promise.resolve();
  },

  getPermissions: () => Promise.resolve(),
};

export default authProvider;
