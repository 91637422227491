import config from '../../../config';
import { getHighestRole, getRoles } from '../../auth/services';
import {
  EmailEpisodeRegisterPayload,
  EmailEpisodeUnregisterPayload,
  EmailUpdatePayload,
  EmailUpdatePeoplePayload,
} from './episodeUpdate/typeDefs';
import {
  EmailRegionRattachementUpdatePayload,
  EmailRegionsAnimationAddPayload,
  EmailRegionsAnimationDeletePayload,
} from './userUpdate/typeDefs';

const sendEmail = async (
  query: string,
  payload:
    | EmailUpdatePayload
    | EmailUpdatePeoplePayload
    | EmailEpisodeUnregisterPayload
    | EmailEpisodeRegisterPayload
    | EmailRegionRattachementUpdatePayload
    | EmailRegionsAnimationDeletePayload
    | EmailRegionsAnimationAddPayload,
) => {
  const variables = payload;

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

export default sendEmail;
