import React from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  FunctionField,
  TextField,
} from 'react-admin';
import TranslateField from '../../components/translateField';
import { downloadCSVForExcel } from '../../components/exporter';
import { speakerEvaluation } from './SpeakerEvaluationExport';

export const SpeakerEvaluationList = props => (
  <List
    {...props}
    exporter={records => {
      speakerEvaluation(records).then(csv =>
        downloadCSVForExcel(csv, 'evaluations_des_intervenants'),
      );
    }}
  >
    <Datagrid rowClick="edit">
      <ReferenceField source="intervenant_id" reference="animator">
        <FunctionField
          render={(user: any) => `${user.firstName} ${user.lastName}`}
          label="resources.speakerEvaluation.fields.animator"
        />
      </ReferenceField>
      <TranslateField source="pathChosen" namespace="resources.class.answers" />
      <TranslateField
        source="enoughTime"
        namespace="resources.speakerEvaluation.answers.enoughTime"
      />
      <TranslateField
        source="startOnTime"
        namespace="resources.speakerEvaluation.answers.startOnTime"
      />
      <TranslateField
        source="sameTeacher"
        namespace="resources.speakerEvaluation.answers.sameTeacher"
      />
      <TranslateField
        source="helpFacilitateSession"
        namespace="resources.speakerEvaluation.answers.helpFacilitateSession"
      />
      <TranslateField
        source="studentAttention"
        namespace="resources.speakerEvaluation.answers.studentAttention"
      />
      <TranslateField
        source="studentInvolvement"
        namespace="resources.speakerEvaluation.answers.studentInvolvement"
      />
      <TranslateField
        source="resolutionOrChallenge"
        namespace="resources.speakerEvaluation.answers.resolutionOrChallenge"
      />
      <TranslateField
        source="resolutionOrChallengeHolding"
        namespace="resources.speakerEvaluation.answers.resolutionOrChallengeHolding"
      />
      <TranslateField
        source="computer"
        namespace="resources.speakerEvaluation.answers.computer"
      />
      <TranslateField
        source="videoProjector"
        namespace="resources.speakerEvaluation.answers.videoProjector"
      />
      <TranslateField
        source="technicalIssueEJ"
        namespace="resources.speakerEvaluation.answers.technicalIssueEJ"
      />
      <TranslateField
        source="technicalIssueRoom"
        namespace="resources.speakerEvaluation.answers.technicalIssueRoom"
      />
      <TranslateField
        source="overallImpression"
        namespace="resources.speakerEvaluation.answers.overallImpression"
      />
      <TextField source="comments" />
    </Datagrid>
  </List>
);
export default SpeakerEvaluationList;
