import React from 'react';
import { Button, useDataProvider, useRefresh, useNotify } from 'react-admin';

const AbsentButton = ({ record }: { record?: any }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Button
      label="absent"
      color="primary"
      onClick={async () => {
        try {
          await dataProvider.delete('user_training', {
            id: record.id,
            previousData: record,
          });

          notify(
            "L'utilisateur a été supprimé de la liste des participants",
            'info',
          );
          refresh();
        } catch (e) {
          notify(e, 'warning');
        }
      }}
    />
  );
};

export default AbsentButton;
