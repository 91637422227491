import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-final-form';
import range from 'lodash/range';
import TimeInput from './TimeInput';
import DateInput from './DateInput';
import config from '../config';
import { getHighestRole, getRoles } from '../technical/auth/services';

const optionText = choice =>
  choice?.firstName && choice?.lastName
    ? `${choice.firstName} ${choice.lastName}`
    : `Aucun animateur`;

const DateInputEp1 = props => {
  const { episodes } = props;
  const form = useForm();
  return (
    <DateInput
      label="Date"
      source="dateEp1"
      initialValue={episodes.length > 0 ? episodes[0].dateStart : undefined}
      onChange={value => {
        form.change('dateEp2', value);
        form.change('dateEp3', value);
      }}
    />
  );
};

const TimeStartInputEp1 = props => {
  const form = useForm();
  const { episodes } = props;
  return (
    <TimeInput
      source="timeStartEp1"
      label="Heure de début"
      initialValue={episodes.length > 0 ? episodes[0].dateStart : undefined}
      onChange={value => {
        form.change('timeStartEp2', value);
        form.change('timeStartEp3', value);
      }}
    />
  );
};

const TimeEndInputEp1 = props => {
  const form = useForm();
  const { episodes } = props;
  return (
    <TimeInput
      source="timeEndEp1"
      label="Heure de fin"
      initialValue={episodes.length > 0 ? episodes[0].dateEnd : undefined}
      onChange={value => {
        form.change('timeEndEp2', value);
        form.change('timeEndEp3', value);
      }}
    />
  );
};

const ExpertIdInputEp1 = props => {
  const form = useForm();
  const { experts } = props;
  const { episodes } = props;
  return (
    <AutocompleteInput
      optionText={optionText}
      source="expertIdEp1"
      label="Expert"
      choices={experts}
      initialValue={episodes.length > 0 ? episodes[0].expertId : undefined}
      onChange={value => {
        form.change('expertIdEp2', value);
        form.change('expertIdEp3', value);
      }}
      shouldRenderSuggestions={value => value.length > 0}
    />
  );
};

const ClassroomNumberInputEp1 = props => {
  const form = useForm();
  const { episodes } = props;
  return (
    <TextInput
      label="Numéro de classe"
      source="classroomNumberEp1"
      initialValue={
        episodes.length > 0 ? episodes[0].classroomNumber : undefined
      }
      onChange={value => {
        form.change('classroomNumberEp2', value.target.value);
        form.change('classroomNumberEp3', value.target.value);
      }}
    />
  );
};

const TeacherIdInputEp1 = props => {
  const form = useForm();
  const { teachers } = props;
  const { episodes } = props;

  return (
    <AutocompleteInput
      optionText={optionText}
      label="Enseignant"
      source="teacherIdEp1"
      choices={teachers}
      initialValue={episodes.length > 0 ? episodes[0].teacherId : undefined}
      onChange={value => {
        form.change('teacherIdEp2', value);
        form.change('teacherIdEp3', value);
      }}
      shouldRenderSuggestions={value => value.length > 0}
    />
  );
};

const FirstAnimateurIdInputEp1 = props => {
  const form = useForm();
  const { animators } = props;
  const { episodes } = props;
  return (
    <AutocompleteInput
      optionText={optionText}
      source="firstAnimateurIdEp1"
      label="Animateur 1"
      choices={animators}
      initialValue={
        episodes.length > 0 ? episodes[0].firstAnimateurId : undefined
      }
      onChange={value => {
        form.change('firstAnimateurIdEp2', value);
        form.change('firstAnimateurIdEp3', value);
      }}
      shouldRenderSuggestions={value => value.length > 0}
    />
  );
};

const EpisodesFormInputs = props => {
  const { episodes } = props;
  const [experts, setExperts] = React.useState([]);
  const [animators, setAnimators] = React.useState([]);
  const [teachers, setTeachers] = React.useState([]);
  React.useEffect(() => {
    const queryTeachers = `query GetPeoples{
      contact {
        id
        firstName
        lastName
      }
    }`;
    const queryVolunteers = `query GetPeoples{
      user(where: {type: {_in: ["expert", "animator"]}}) {
        id
        firstName
        lastName
        type
      }
    }`;
    const fetchTeachers = async () => {
      const response = await fetch(`${config.graphqlUri}/v1/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Hasura-Role': getHighestRole(getRoles()),
        },
        body: JSON.stringify({ query: queryTeachers }),
      });
      const { data } = await response.json();
      setTeachers(data.contact);
    };
    const fetchAnimators = async () => {
      const response = await fetch(`${config.graphqlUri}/v1/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Hasura-Role': getHighestRole(getRoles()),
        },
        body: JSON.stringify({ query: queryVolunteers }),
      });
      const { data } = await response.json();
      setAnimators(data.user);
    };

    const fetchExperts = async () => {
      const response = await fetch(`${config.graphqlUri}/v1/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Hasura-Role': getHighestRole(getRoles()),
        },
        body: JSON.stringify({ query: queryVolunteers }),
      });
      const { data } = await response.json();
      setExperts(data.user);
    };
    fetchTeachers();
    fetchAnimators();
    fetchExperts();
  }, []);

  // Temporary fix, only display 3 episodes because the request don't handle more for now
  const values =
    episodes.length > 0 ? range(2, Math.min(4, episodes.length + 1)) : [2, 3];
  return (
    <Box width={1}>
      {episodes.length > 3 && (
        <Typography variant="caption">
          Seul les trois premiers épisodes s&apos;affichent
        </Typography>
      )}
      <Box width={1}>
        <Box display="flex">
          <Box mr="2em" ml="2em">
            <Typography variant="h6" gutterBottom>
              Episode 1
            </Typography>
            <BooleanInput
              source="unplanifyEp1"
              label="Déplanifier l'épisode 1"
            />
            <FormDataConsumer>
              {({ formData }) =>
                !formData.unplanifyEpisode1 && (
                  <Box width={1} display="flex" flexDirection="column">
                    <DateInputEp1 episodes={episodes} />
                    <TimeStartInputEp1 episodes={episodes} />
                    <TimeEndInputEp1 episodes={episodes} />
                  </Box>
                )
              }
            </FormDataConsumer>
            <TeacherIdInputEp1 teachers={teachers} episodes={episodes} />
            <ClassroomNumberInputEp1 episodes={episodes} />
            <ExpertIdInputEp1 experts={experts} episodes={episodes} />
            <FirstAnimateurIdInputEp1
              animators={animators}
              episodes={episodes}
            />
            {/* Removed in #129105 */}
            {/* <SecondAnimateurIdInputEp1
              animators={animators}
              episodes={episodes}
            /> */}
          </Box>
          {values.map(value => {
            return (
              <Box mr="2em" ml="2em" key={value}>
                <Typography variant="h6" gutterBottom>
                  Episode {value}
                </Typography>
                <BooleanInput
                  source={`unplanifyEp${value}`}
                  label={`Déplanifier l'épisode ${value}`}
                />
                <FormDataConsumer>
                  {({ formData }) =>
                    formData &&
                    !formData[`unplanifyEpisode${value}`] && (
                      <Box width={1} display="flex" flexDirection="column">
                        <DateInput
                          source={`dateEp${value}`}
                          label="Date"
                          initialValue={
                            episodes.length > 0
                              ? episodes[parseInt(value, 10) - 1].dateStart
                              : undefined
                          }
                        />
                        <TimeInput
                          source={`timeStartEp${value}`}
                          label="Heure de début"
                          initialValue={
                            episodes.length > 0
                              ? episodes[parseInt(value, 10) - 1].dateStart
                              : undefined
                          }
                        />
                        <TimeInput
                          source={`timeEndEp${value}`}
                          label="Heure de fin"
                          initialValue={
                            episodes.length > 0
                              ? episodes[parseInt(value, 10) - 1].dateEnd
                              : undefined
                          }
                        />
                      </Box>
                    )
                  }
                </FormDataConsumer>
                <AutocompleteInput
                  optionText={optionText}
                  source={`teacherIdEp${value}`}
                  choices={teachers}
                  initialValue={
                    episodes.length > 0
                      ? episodes[parseInt(value, 10) - 1].teacherId
                      : undefined
                  }
                  shouldRenderSuggestions={v => v.length > 0}
                  label="Enseignant"
                />
                <TextInput
                  label="Numéro de classe"
                  source={`classroomNumberEp${value}`}
                  initialValue={
                    episodes.length > 0
                      ? episodes[parseInt(value, 10) - 1].classroomNumber
                      : undefined
                  }
                />
                <AutocompleteInput
                  optionText={optionText}
                  source={`expertIdEp${value}`}
                  choices={experts}
                  initialValue={
                    episodes.length > 0
                      ? episodes[parseInt(value, 10) - 1].expertId
                      : undefined
                  }
                  shouldRenderSuggestions={v => v.length > 0}
                  label="Expert"
                />
                <AutocompleteInput
                  optionText={optionText}
                  source={`firstAnimateurIdEp${value}`}
                  choices={animators}
                  initialValue={
                    episodes.length > 0
                      ? episodes[parseInt(value, 10) - 1].firstAnimateurId
                      : undefined
                  }
                  shouldRenderSuggestions={v => v.length > 0}
                  label="Animateur 1"
                />
                {/* Removed in #129105 */}
                {/* <AutocompleteInput
                  optionText={optionText}
                  source={`secondAnimateurIdEp${value}`}
                  choices={animators}
                  initialValue={
                    episodes.length > 0
                      ? episodes[parseInt(value, 10) - 1].secondAnimateurId
                      : undefined
                  }
                  shouldRenderSuggestions={v => v.length > 0}
                  label="Animateur 2"
                /> */}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default EpisodesFormInputs;
