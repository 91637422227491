import moment from 'moment';
import 'moment/locale/fr';
import translation from '../../../../translation/fr';
import {
  EpisodeDetails,
  queryEmailPeopleUpdate,
  VolontairesEpisode,
} from './typeDefs';
import sendEmail from '../sendEmail';

moment.locale('fr');

const sendMailsPeopleUpdate = async (
  previousEpisode: any,
  episode: any,
  episodeDetails: EpisodeDetails,
  volontaires: VolontairesEpisode,
  emails: string[],
) => {
  const needToSendEmailAfterJ7 =
    previousEpisode.firstAnimateurId !== episode.firstAnimateurId ||
    previousEpisode.secondAnimateurId !== episode.secondAnimateurId ||
    previousEpisode.expertId !== episode.expertId ||
    previousEpisode.teacherId !== episode.teacherId ||
    previousEpisode.classroomNumber !== episode.classroomNumber;

  // We send a remind 7 days before the episode so if the changes occurs more
  // than a week before the reminder, we don't need to send the mail
  const isItBeforeReminder =
    moment(episode.dateStart).add(-1, 'week') < moment();

  if (needToSendEmailAfterJ7 && isItBeforeReminder) {
    await Promise.all(
      emails.map(email =>
        sendEmail(queryEmailPeopleUpdate, {
          id: episode.id,
          email,
          className: episodeDetails.pack.name,
          classLevel:
            translation.resources.class.levels[episodeDetails.pack.level],
          classType:
            translation.resources.class.types[episodeDetails.pack.type],
          classTheme:
            translation.resources.class.answers[episodeDetails.pack.theme],
          classroomNumber: episode.classroomNumber,
          episodeNumber: episode.number.toString(),
          episodeHour: moment(episode.dateStart).format('HH:mm'),
          episodeDate: moment(episode.dateStart).format('DD[/]MM[/]YYYY'),
          establishmentName: episodeDetails.establishment.name,
          establishmentCity: episodeDetails.establishment.city,
          teacherName: episodeDetails.teacher
            ? `${episodeDetails.teacher?.firstName} ${episodeDetails.teacher?.lastName}`
            : undefined,
          firstAnimatorName: volontaires.firstAnimateur
            ? `${volontaires.firstAnimateur?.firstName} ${volontaires.firstAnimateur?.lastName}`
            : undefined,
          firstAnimatorPhone: volontaires.firstAnimateur?.phone,
          secondAnimatorName: volontaires.secondAnimateur
            ? `${volontaires.secondAnimateur?.firstName} ${volontaires.secondAnimateur?.lastName}`
            : undefined,
          secondAnimatorPhone: volontaires.secondAnimateur?.phone,
          expertName: volontaires.expert
            ? `${volontaires.expert?.firstName} ${volontaires.expert?.lastName}`
            : undefined,
          expertPhone: volontaires.expert?.phone,
          regionManager: `${episodeDetails.regionManager.firstName} ${episodeDetails.regionManager.lastName}`,
        }),
      ),
    );
  }
};

export default sendMailsPeopleUpdate;
