import React from 'react';
import { useTranslate, SelectArrayInput } from 'react-admin';

const TypeFilterInput = ({ choicesVisible, alwaysOn, ...props }) => {
  const translate = useTranslate();
  return (
    <SelectArrayInput
      choices={choicesVisible.map(choice => ({
        id: choice,
        name: translate(`user.types.${choice}`),
      }))}
      source="type._in"
      {...props}
    />
  );
};

export default TypeFilterInput;
