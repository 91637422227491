import React from 'react';
import { Button, useDataProvider, useRefresh, useNotify } from 'react-admin';

const InvalidateButton = ({ record }: { record?: any }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Button
      label="invalider"
      color="primary"
      onClick={async () => {
        try {
          await dataProvider.update('user_training', {
            id: record.id,
            data: {
              invalidationDate: new Date(),
              validationDate: null,
            },
          });
          const {
            data: { type },
          } = await dataProvider.getOne('training', {
            id: record.training_id,
          });

          if (type !== 'other') {
            await dataProvider.update('user', {
              id: record.user_id,
              data: {
                isActive: false,
              },
            });
          }
          refresh();
        } catch (e) {
          notify(e, 'warning');
        }
      }}
    />
  );
};

export default InvalidateButton;
