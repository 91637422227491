import React from 'react';

function AddressField({ record }: any) {
  return (
    <span>
      {[record.address, record.postalCode, record.city].every(Boolean) &&
        `${record.address} ${record.postalCode}, ${record.city}`}
    </span>
  );
}

export default AddressField;
