import React from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  Create,
  email,
  EmailField,
  List,
  minLength,
  ReferenceField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  Filter,
  Toolbar,
  SaveButton,
  SimpleShowLayout,
  Show,
  BooleanInput,
  NullableBooleanInput,
  FunctionField,
  DateField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin';

import TranslateField from 'components/translateField';
import { useForm } from 'react-final-form';
import {
  additionalUserShowProps,
  getRegionRattachement,
  getRoles,
  isDataManager,
  isOperator,
  isRegionManager,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from '../../technical/auth/services';
import civilityChoices from '../../components/CivilityChoices';
import TranslateChoices from '../../components/TranslateChoices';
import PartialSearchInput from '../../components/PartialSearchInput';
import SelectFilterInput from '../../components/SelectFilterInput';
import {
  downloadCSVForExcel,
  exportIntervenants,
  exporter,
} from '../../components/exporter';
import StatusFilterInput from '../../components/StatusFilterInput';
import TypeFilterInput from '../../components/TypeFilterInput';
import RegionFilter from '../../components/RegionFilter';
import ListActions from '../../components/ListActions';
import { UserFormContext, UserFormContextProvider } from './user-form-context';

const validateName = [minLength(3), required()];
const validateEmail = [required(), email()];

const roles = getRoles();
const origins = [
  'active-in-company',
  'egee',
  'independent',
  'civic-service',
  'seconded-employee',
  'ej-employee',
];

const userToSiteStatus: { [key: string]: string | undefined } = {
  'active-in-company': 'partner-company',
  egee: 'egee',
  independent: 'independent',
  'seconded-employee': 'partner-company',
  'ej-employee': 'ej',
  'civic-service': 'civic-service',
};

const types = shouldSeeAllRegionsRoles(roles)
  ? ['administrator', 'operator', 'region-manager', 'national']
  : ['region-manager'];

const UserFilter = ({ typesVisible, operatorView, ...props }) => (
  <Filter {...props}>
    <RegionFilter
      source="regionId"
      alwaysOn={!operatorView}
      label="Région de rattachement"
    />
    <PartialSearchInput source="firstName" />
    <PartialSearchInput source="lastName" />
    <PartialSearchInput source="email" />
    <SelectFilterInput model="user" source="origin" values={origins} />
    <NullableBooleanInput source="isActive" alwaysOn />
    <TypeFilterInput
      source="type._in"
      label="resources.animator.fields.type"
      choicesVisible={typesVisible}
      alwaysOn
    />
    <StatusFilterInput
      source="formationStatus._ilike"
      label="resources.animator.fields.formationStatus"
    />
    {!operatorView && (
      <NullableBooleanInput
        source="has_multiple_region"
        label="resources.animator.fields.multiple"
      />
    )}
  </Filter>
);

export const UserList = (props: any) => {
  const currentUserRegion = getRegionRattachement();

  const postRowClick = (_id, _basepath, record) =>
    isDataManager(roles) ||
    (isOperator(roles) && record.regionId === currentUserRegion)
      ? 'edit'
      : 'show';

  return (
    <List
      {...props}
      filters={
        <UserFilter
          typesVisible={['new', 'animator', 'expert']}
          operatorView={false}
        />
      }
      filterDefaultValues={{
        isActive: true,
        type: { _in: ['new', 'animator', 'expert'] },
        regionId:
          isOperator(roles) || isRegionManager(roles)
            ? currentUserRegion
            : undefined,
      }}
      sort={{ field: 'lastName', order: 'ASC' }}
      bulkActionButtons={false}
      exporter={records =>
        exportIntervenants(records, roles).then(csv =>
          downloadCSVForExcel(csv, 'intervenants'),
        )
      }
    >
      <Datagrid rowClick={postRowClick}>
        <TranslateField namespace="user.civility" source="civility" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phone" />
        <EmailField source="email" />
        <TranslateField namespace="user.types" source="type" />
        <TranslateField namespace="user.origins" source="origin" />
        <ReferenceField source="siteId" reference="site">
          <FunctionField
            render={site =>
              `${site.organisationName} ${site.address} ${site.postalCode} ${site.city}`
            }
          />
        </ReferenceField>
        <TextField source="formationStatus" />
        <BooleanField source="isActive" />
        <ReferenceField source="regionId" reference="region">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField
          label="Intervenants hors RR"
          source="has_multiple_region"
        />
        {isOperator(roles) && (
          <FunctionField
            label="Sens mobilité (Entrant Sortant)"
            render={record => {
              const isFromAnotherRegion = record.regionId !== currentUserRegion;
              const hasMultipleRegions = record.has_multiple_region;
              if (isFromAnotherRegion) {
                return 'Entrant';
              }
              if (hasMultipleRegions) {
                return 'Sortant';
              }
              return '';
            }}
          />
        )}
        {isOperator(roles) && (
          <FunctionField
            label="Expert externe"
            render={record => {
              const isExpert = record.type === 'expert';
              const isFromAnotherRegion = record.regionId !== currentUserRegion;
              if (isExpert && isFromAnotherRegion) {
                return 'E';
              }
            }}
          />
        )}
      </Datagrid>
    </List>
  );
};

export const OperatorList = (props: any) => {
  return (
    <List
      {...props}
      filters={<UserFilter typesVisible={types} operatorView />}
      filterDefaultValues={{
        isActive: true,
        type: { _in: types },
      }}
      sort={{ field: 'lastName', order: 'ASC' }}
      bulkActionButtons={false}
      actions={<ListActions />}
      exporter={records =>
        exporter(records).then(csv => downloadCSVForExcel(csv, 'opérateurs'))
      }
    >
      <Datagrid rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'}>
        <TranslateField namespace="user.civility" source="civility" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="phone" />
        {shouldSeeAllRegionsRoles(roles) && (
          <ReferenceField source="regionId" reference="region">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TranslateField namespace="user.types" source="type" />
        <BooleanField source="isActive" />
      </Datagrid>
    </List>
  );
};

const RegionInput = (
  <FormDataConsumer>
    {({ formData, ...rest }) => {
      const form = useForm();

      return (
        (isDataManager(roles) || isOperator(roles)) && (
          <UserFormContext.Consumer>
            {p => (
              <ReferenceInput
                source="regionId"
                reference="region"
                filterToQuery={(searchText: string) => ({
                  name: { _ilike: `%${searchText}%` },
                })}
                onChange={() => form.change('siteId', null)}
                {...rest}
              >
                {isDataManager(roles) && p.canChangeRegion ? (
                  <AutocompleteInput optionText="name" />
                ) : (
                  <SelectInput optionText="name" disabled />
                )}
              </ReferenceInput>
            )}
          </UserFormContext.Consumer>
        )
      );
    }}
  </FormDataConsumer>
);

const OldRegionInput = (
  <FormDataConsumer>
    {({ formData, ...rest }) => {
      if (
        !formData.oldRegionId ||
        !['new', 'animator', 'expert'].includes(formData.type)
      ) {
        return null;
      }

      return (
        (isDataManager(roles) || isOperator(roles)) && (
          <ReferenceInput source="oldRegionId" reference="region" {...rest}>
            <SelectInput optionText="name" disabled />
          </ReferenceInput>
        )
      );
    }}
  </FormDataConsumer>
);

const OriginInput = (
  <FormDataConsumer>
    {({ formData, ...rest }) => {
      const form = useForm();

      return (
        ['new', 'animator', 'expert'].includes(formData.type) && (
          <SelectInput
            choices={TranslateChoices(origins, 'user.origins')}
            source="origin"
            optionText="name"
            onChange={() => form.change('siteId', null)}
            {...rest}
          />
        )
      );
    }}
  </FormDataConsumer>
);
const SiteInput = (
  <FormDataConsumer>
    {({ formData, ...rest }) =>
      ['new', 'animator', 'expert'].includes(formData.type) && (
        <ReferenceInput
          source="siteId"
          reference="site"
          filter={{
            _and: [
              { regionId: { _eq: formData.regionId } },
              { origin: { _eq: userToSiteStatus[formData.origin] } },
            ],
          }}
          filterToQuery={(searchText: string) => ({
            organisationName: { _ilike: `%${searchText}%` },
          })}
          allowEmpty
          emptyText="Aucun site"
          {...rest}
        >
          <AutocompleteInput optionText="organisationName" />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>
);
const TypeInput = (
  <FormDataConsumer>
    {({ formData, ...rest }) =>
      !['new', 'animator', 'expert'].includes(formData.type) &&
      (isDataManager(roles) || isOperator(roles)) && (
        <SelectInput
          source="type"
          choices={TranslateChoices(types, 'user.types')}
          validate={required()}
          {...rest}
        />
      )
    }
  </FormDataConsumer>
);

const UserToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const validateUserCreation = values => {
  const errors = { region_animation_ids: '' };
  if (
    ['new', 'animator', 'expert'].includes(values.type) &&
    values.region_animation_ids &&
    !values.region_animation_ids.includes(values.regionId)
  ) {
    errors.region_animation_ids =
      "La région de rattachement doit être incluse au sein des régions d'animation";
    return errors;
  }
  return {};
};

const UserForm = (
  <SimpleForm
    redirect="list"
    toolbar={<UserToolbar />}
    validate={validateUserCreation}
  >
    <SelectInput
      source="civility"
      choices={civilityChoices}
      validate={required()}
    />
    <TextInput source="firstName" validate={validateName} />
    <TextInput source="lastName" validate={validateName} />
    <TextInput source="email" validate={validateEmail} />
    <TextInput source="phone" />
    <FormDataConsumer>
      {({ formData }) => {
        if (
          !formData.changeRegionDate ||
          !['new', 'animator', 'expert'].includes(formData.type)
        ) {
          return null;
        }
        return (
          <>
            <TextField
              source="text"
              record={{ text: 'Date de changement:  ' }}
            />
            <DateField source="changeRegionDate" record={formData} />
          </>
        );
      }}
    </FormDataConsumer>

    {OldRegionInput}

    {RegionInput}

    <FormDataConsumer>
      {({ formData }) =>
        ['new', 'animator', 'expert'].includes(formData.type) &&
        (getRegionRattachement() === formData.regionId ||
          isDataManager(roles)) && (
          <ReferenceArrayInput
            source="region_animation_ids"
            reference="region"
            label="Région d'animation"
            filterToQuery={(searchText: string) => ({
              name: { _ilike: `%${searchText}%` },
            })}
          >
            <AutocompleteArrayInput
              optionText="name"
              label="Région d'animation"
            />
          </ReferenceArrayInput>
        )
      }
    </FormDataConsumer>

    {OriginInput}

    {SiteInput}

    {TypeInput}
    <BooleanInput source="isActive" />
  </SimpleForm>
);

export const UserEdit = ({ id, ...rest }: any) => {
  return (
    <UserFormContextProvider userId={id}>
      <Edit {...rest} {...additionalUserShowProps(roles)} id={id}>
        {UserForm}
      </Edit>
    </UserFormContextProvider>
  );
};

export const UserCreate = ({ id, ...rest }: any) => {
  return (
    <UserFormContextProvider userId={id} create>
      <Create {...rest} id={id}>
        {UserForm}
      </Create>
    </UserFormContextProvider>
  );
};

export const UserShow = (props: any) => {
  const pr = props;
  return (
    <Show {...props} {...additionalUserShowProps(roles)}>
      <SimpleShowLayout>
        <TranslateField namespace="user.civility" source="civility" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="phone" />
        {(isDataManager(roles) || isShowOnlyRoles(roles)) &&
          pr.resource === 'animator' && (
            <ReferenceField
              source="oldRegionId"
              reference="region"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          )}
        {(isDataManager(roles) || isShowOnlyRoles(roles)) &&
          pr.resource === 'animator' && (
            <DateField source="changeRegionDate" label="Date de changement" />
          )}
        <ReferenceField source="regionId" reference="region" link={false}>
          <TextField source="name" />
        </ReferenceField>
        {(isDataManager(roles) || isShowOnlyRoles(roles)) &&
          pr.resource === 'animator' && (
            <ReferenceArrayField
              source="region_animation_ids"
              reference="region"
              label="Régions d'animation"
            >
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          )}
        <TranslateField namespace="user.types" source="type" />
        {pr && pr.resource === 'animator' && (
          <TranslateField namespace="user.origins" source="origin" />
        )}
        {pr && pr.resource === 'animator' && (
          <ReferenceField source="siteId" reference="site">
            <TextField source="organisationName" />
          </ReferenceField>
        )}
        <BooleanField source="isActive" />
      </SimpleShowLayout>
    </Show>
  );
};
