export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  regionId?: string;
  oldRegionId?: string;
  changeRegionDate?: Date;
  type:
    | 'administrator'
    | 'animator'
    | 'expert'
    | 'new'
    | 'operator'
    | 'national'
    | 'region-manager';
  civility: string;
  phone: string;
  origin: string;
  newPackNotification: boolean;
  siteId: string;
};

export interface EmailRegionRattachementUpdatePayload {
  userEmail: string;
  userInfo: string;
  newRegionId: string;
  oldRegionId: string;
  adminEmail: string;
}
export const queryEmailRegionRattachementUpdate = `mutation Email (
  $userEmail: String!
  $userInfo: String!
  $newRegionId: String!
  $oldRegionId: String!
  $adminEmail: String!
) {
  regionRattachementUpdateEmail(input: {
  userEmail: $userEmail
  userInfo: $userInfo
  newRegionId: $newRegionId
  oldRegionId: $oldRegionId
  adminEmail: $adminEmail
  }) {
    success
  }
}`;

export interface EmailRegionsAnimationDeletePayload {
  userMail: string;
  adminMail: string;
  oldRegionId: string;
  regionId: string;
  userInfo: string;
}
export const queryEmailRegionsAnimationDelete = `mutation Email (
  $userMail: String!
  $adminMail: String!
  $oldRegionId: String!
  $regionId: String!
  $userInfo: String!
) {
  regionAnimationDeleteEmail(input: {
    userMail: $userMail
    adminMail: $adminMail
    oldRegionId: $oldRegionId
    regionId: $regionId
    userInfo: $userInfo
  }) {
    success
  }
}`;

export interface EmailRegionsAnimationAddPayload {
  userMail: string;
  adminMail: string;
  newRegionId: string;
  regionId: string;
  userInfo: string;
}
export const queryEmailRegionsAnimationAdd = `mutation Email (
  $userMail: String!
  $adminMail: String!
  $newRegionId: String!
  $regionId: String!
  $userInfo: String!
) {
  regionAnimationAddEmail(input: {
    userMail: $userMail
    adminMail: $adminMail
    newRegionId: $newRegionId
    regionId: $regionId
    userInfo: $userInfo
  }) {
    success
  }
}`;
