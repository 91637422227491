import React from 'react';

export const address = record =>
  record &&
  [record.name, record.address, record.postalCode, record.city].every(Boolean)
    ? `${record.name} - ${record.address} ${record.postalCode}, ${record.city}`
    : 'Aucun site';

export const NameAddressField = ({ record }: any) => {
  return <span>{address(record)}</span>;
};
