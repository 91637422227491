import React from 'react';
import { Button, useDataProvider, useRefresh, useNotify } from 'react-admin';

const ValidateButton = ({ record }: { record?: any }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Button
      label="valider"
      onClick={async () => {
        try {
          await dataProvider.update('user_training', {
            id: record.id,
            data: {
              validationDate: new Date(),
              invalidationDate: null,
            },
          });

          const {
            data: { type },
          } = await dataProvider.getOne('training', {
            id: record.training_id,
          });

          if (type === 'new') {
            await dataProvider.update('user', {
              id: record.user_id,
              data: {
                type: 'animator',
              },
            });

            notify('notifications.animator');
          }

          if (type === 'expert') {
            await dataProvider.update('user', {
              id: record.user_id,
              data: {
                type: 'expert',
              },
            });

            notify('notifications.expert');
          }

          refresh();
        } catch (e) {
          notify(e, 'warning');
        }
      }}
    />
  );
};

export default ValidateButton;
