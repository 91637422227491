function TranslateChoices(
  choices: (string | number | null)[],
  namespace: string,
) {
  return choices.map(value => ({
    id: value,
    name: `${namespace}.${value}`,
  }));
}

export default TranslateChoices;
