import jsonExport from 'jsonexport';
import moment from 'moment';
import i18nProvider from '../../translation';
import { categories, types } from '../establishment/resource';
import config from '../../config';
import { getHighestRole, getRoles } from '../../technical/auth/services';
import {
  Pack,
  Exportable,
  extractFullname,
  extractUser,
  importExportLevels,
} from '../../components/exporter';

const QueryExport = async function QueryExport(packId: string[]) {
  const query = `query Export($packId: [uuid!]) {
  class(where: {id: {_in: $packId}}) {
    level
    name
    publicationDate
    type
    theme
    establishment {
      id
      contactByCollegereferentid {
        firstName
        lastName
      }
      category
      name
      referenceAcademy
      postalCode
      city
      address
      type
        isEducationalCity
      phone
      region {
        name
        id
      }
    }
    episodes (order_by: {dateStart: asc}){
      dateStart
      number
      firstGuest
      secondGuest
      classroomNumber
      expert {
        firstName
        lastName
        email
        phone
        regionId
        site {
          organisationName
        }
      }
      teacher {
        firstName
        lastName
      }
      animateur_1 {
        firstName
        lastName
        email
        phone
        site {
          organisationName
        }
      }
      animateur_2 {
        firstName
        lastName
        email
        phone
        site {
          organisationName
        }
      }
    }
  }
}`;

  const variables = { packId };

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

const FlattenPack = async function FlattenPack(
  packs: Pack[],
): Promise<Exportable[][]> {
  const packIds = packs.map(pack => {
    return pack.id;
  });
  const response = await QueryExport(packIds);
  const packsData = await response.json().then(res => {
    return res.data.class;
  });

  return packsData.map(packData => {
    const establishment = packData?.establishment;
    const episodes = packData?.episodes;
    return episodes.map((episode, index) => {
      return {
        dateStart: episode.dateStart
          ? moment(episode.dateStart).format('D/M/YYYY HH:mm')
          : '',
        className: packData.name,
        level: Object.keys(importExportLevels).find(
          key => importExportLevels[key] === packData.level,
        ),
        type: i18nProvider.translate(`resources.class.types.${packData.type}`),
        theme: i18nProvider.translate(
          `resources.class.answers.${packData.theme}`,
        ),
        episodeIndex: (index + 1).toString(),
        published: packData.publicationDate ? 'Oui' : 'Non',
        expert: extractUser(episode.expert),
        firstAnimateur: extractUser(episode.animateur_1),
        secondAnimateur: extractUser(episode.animateur_2),
        region: String(establishment?.region?.name),
        episodes: {
          0: {
            dateStart:
              episodes[0] && episodes[0].dateStart
                ? moment(episodes[0].dateStart).format('D/M/YYYY HH:mm')
                : '',
          },
          1: {
            dateStart:
              episodes[1] && episodes[1].dateStart
                ? moment(episodes[1].dateStart).format('D/M/YYYY HH:mm')
                : '',
          },
          2: {
            dateStart:
              episodes[2] && episodes[2].dateStart
                ? moment(episodes[2].dateStart).format('D/M/YYYY HH:mm')
                : '',
          },
        },
        establishment: {
          id: establishment.id ? establishment.id : '',
          category: establishment.category
            ? categories[establishment.category]
            : '',
          name: establishment.name ? establishment.name : '',
          academy: establishment.referenceAcademy
            ? establishment.referenceAcademy
            : '',
          address: establishment.address ? establishment.address : '',
          city: establishment.city ? establishment.city : '',
          postalCode: establishment.postalCode
            ? `="${establishment.postalCode}"`
            : '',
          type: establishment.type ? types[establishment.type] : '',
          isEducationalCity: [true, false].includes(
            establishment.isEducationalCity,
          )
            ? { true: 'Oui', false: 'Non' }[
                establishment.isEducationalCity.toString()
              ]
            : '',
          phone: establishment.phone ? `="${establishment.phone}"` : '',
          establishmentReferent: establishment?.contactByCollegereferentid
            ? extractFullname(establishment.contactByCollegereferentid)
            : '',
        },
        teacher: episode?.teacher ? extractFullname(episode.teacher) : '',
        room: episode.classroomNumber,
        firstGuest: episode.firstGuest ? episode.firstGuest : '',
        secondGuest: episode.secondGuest ? episode.secondGuest : '',
        expertExterne:
          episode.expert &&
          establishment.region &&
          episode.expert.regionId !== establishment.region.id
            ? 'E'
            : '',
      };
    });
  });
};

export default async function exporter(packs: Pack[]): Promise<string> {
  return jsonExport(
    (await FlattenPack(packs)).reduce((agg, pack) => [...agg, ...pack]),
    {
      rename: [
        'Date classe',
        'Nom Classe',
        'Niveau',
        'Type',
        'Thématique',
        'Episode',
        'Publié',
        'Expert',
        'Email Expert',
        'Tel Expert',
        'Organisme Expert',
        'Animateur',
        'Email Animateur',
        'Tel Animateur',
        'Organisme Animateur',
        'Animateur2',
        'Email Anim2',
        'Tel Anim2',
        'Organisme Anim2',
        'Region EJ',
        '1ere Session',
        '2eme Session',
        '3eme Session',
        'Id du site',
        'Type de site',
        'Nom public',
        'Academie',
        'Adresse',
        'Ville',
        'Code Postal',
        'Classement',
        'Cite Educative',
        'Tel fixe site',
        'Referent Collège',
        'Enseignant Referent Classe',
        'Salle',
        'Invite',
        'Invite2',
        'Expert externe',
      ],
      rowDelimiter: ';',
    },
  );
}
