import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import frenchResources from './fr';

// https://github.com/marmelab/react-admin/blob/master/docs/Translation.md

export default polyglotI18nProvider(
  () => ({ ...frenchMessages, ...frenchResources }),
  'fr',
);
