import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  img: {
    height: '50px',
    marginRight: '10px',
  },
});

const MyAppBar = props => {
  const classes = useStyles();
  const styles: any = {};
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      styles.backgroundColor = '#FEC800';
    } else {
      styles.backgroundColor = '#00fe90';
    }
  }
  return (
    <AppBar {...props} style={styles}>
      <img src="/AppLogo.svg" alt="Logo EJ" className={classes.img} />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
