import sendMailsRegionRattachementUpdate from './sendMailsRegionRattachementUpdate';
import sendMailsRegionsAnimationAdd from './sendMailsRegionsAnimationAdd';
import sendMailsRegionsAnimationDelete from './sendMailsRegionsAnimationDelete';
import { UserData } from './typeDefs';

// eslint-disable-next-line import/prefer-default-export
export const getOne = async function getOne(
  resource: string,
  params: { id?: string },
  requestHandler: any,
) {
  return (await requestHandler('GET_ONE', resource, params)).data;
};

export const userUpdate = async function userUpdate(
  previousUser: UserData,
  user: UserData,
  deletedRegionsAnimationIds: [],
  createdRegionsAnimationIds: [],
) {
  // Envoie du mail prevenant la modification de la date de l'épisode only if regionId changed
  if (previousUser.regionId !== user.regionId) {
    if (!previousUser.regionId) {
      console.error(' old region rattachement ID undefined');
      return;
    }
    if (user.regionId === undefined) {
      console.error(' new region rattachement ID undefined');
      return;
    }
    await sendMailsRegionRattachementUpdate(
      user,
      user.regionId,
      previousUser.regionId,
    );
  }

  // Envoie des mails d'avertissement de suppression de région d'animations
  deletedRegionsAnimationIds.forEach(async id => {
    if (!id) {
      console.error(' new regions ID undefined');
      return;
    }
    if (user.regionId === undefined) {
      console.error(' region rattachement ID undefined');
      return;
    }
    if (!previousUser.regionId) {
      console.error(' old region rattachement ID undefined');
      return;
    }
    // on envoie pas le mail en cas de déménagement
    if (previousUser.regionId !== id) {
      sendMailsRegionsAnimationDelete(user, user.regionId, id);
    }
  });

  // Envoie des mails d'avertissement d'ajout de région d'animations
  createdRegionsAnimationIds.forEach(async id => {
    if (!id) {
      console.error(' new regions ID undefined');
      return;
    }
    if (user.regionId === undefined) {
      console.error(' region rattachement ID undefined');
      return;
    }
    // on envoie pas le mail en cas de déménagement
    if (user.regionId !== id) {
      sendMailsRegionsAnimationAdd(user, user.regionId, id);
    }
  });
};
