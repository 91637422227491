import moment from 'moment';
import config from '../../../config';
import { getHighestRole, getRoles } from '../../auth/services';

const getOne = async function getOne(
  resource: string,
  params: { [key: string]: string },
  requestHandler: any,
) {
  return (await requestHandler('GET_ONE', resource, params)).data;
};

const sendEmail = async function sendEmail(payload: {
  email: string;
  trainingDate: string;
  regionManager: string;
}) {
  const query = `mutation Email($email: String!, $trainingDate: String!, $regionManager: String!) {
  trainingValidateEmail(input: {
    email: $email,
    trainingDate: $trainingDate,
    regionManager: $regionManager
  }) {
    success
  }
}`;
  const variables = payload;

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

const trainingValidate = async function trainingValidate(
  userTrainingUpdate: {
    id: string;
    validationDate: string;
  },
  requestHandler: any,
) {
  const userTraining = await getOne(
    'user_training',
    { id: userTrainingUpdate.id },
    requestHandler,
  );

  const training = await getOne(
    'training',
    { id: userTraining.training_id },
    requestHandler,
  );

  const user = await getOne(
    'user',
    { id: userTraining.user_id },
    requestHandler,
  );

  const region = await getOne('region', { id: user.regionId }, requestHandler);

  const regionManager = await getOne(
    'user',
    { id: region.managerId },
    requestHandler,
  );

  if (userTraining.validationDate && user && user.isActive && user.email) {
    return sendEmail({
      email: user.email,
      trainingDate: moment(training.dateStart).format('DD[/]MM[/]YYYY'),
      regionManager: `${regionManager.firstName} ${regionManager.lastName}`,
    });
  }
  return null;
};

export default trainingValidate;
