if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error('Set REACT_APP_AUTH0_DOMAIN');
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error('Set REACT_APP_AUTH0_CLIENT_ID');
}

if (!process.env.REACT_APP_AUTH0_REDIRECT_URI) {
  throw new Error('Set REACT_APP_AUTH0_REDIRECT_URI');
}

if (!process.env.REACT_APP_GRAPHQL_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_URI');
}

const config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
  },
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI,
};

export default config;
