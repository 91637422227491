import React from 'react';
import {
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const BulkPublishButton = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();
  const { selectedIds } = props;
  return (
    <Button
      label="resources.class.actions.publish"
      onClick={async () => {
        try {
          await dataProvider.updateMany('class', {
            ids: selectedIds,
            data: { publicationDate: new Date() },
          });
          refresh();
          notify('Les packs ont été publiées', 'info');
          unselectAll('class');
        } catch (e) {
          notify(
            "Une erreur est survenue, les packs n'ont pas été publiés",
            'warning',
          );
        }
      }}
    />
  );
};

export default BulkPublishButton;
