import { Login } from 'ra-ui-materialui';
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import createMyTheme from './Theme/createMyTheme';

interface Props {
  loading?: boolean;
}

const themeLogin = createMyTheme({
  palette: {
    primary: {
      main: '#fa8c16',
    },
  },
});
function DeactivatePage({ loading }: Props) {
  return (
    <Login className="custom-login">
      <img
        src="/AppLogo.svg"
        alt="Logo EJ"
        style={{ width: '100%', maxWidth: '400px', margin: 'auto' }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <ThemeProvider theme={themeLogin}>
            <h3>Votre compte a été désactivé</h3>
            <p>
              Veuillez contactez l&apos;équipe de votre région pour plus
              d&apos;informations.
            </p>
          </ThemeProvider>
        )}
      </div>
    </Login>
  );
}

export default DeactivatePage;
