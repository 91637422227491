import moment from 'moment';
import 'moment/locale/fr';
import translation from '../../../../translation/fr';
import { EpisodeDetails, queryEmailUpdate } from './typeDefs';
import sendEmail from '../sendEmail';
import formatDate from '../../../formatDate/formatDate';

moment.locale('fr');

const sendMailsEpisodeUpdate = async (
  previousEpisode: any,
  episode: any,
  episodeDetails: EpisodeDetails,
  emails: string[],
) => {
  // Send mails only if dateStart or dateEnd have changed
  const needToSendEmailEpisodeUpdate =
    previousEpisode.dateStart !== episode.dateStart ||
    previousEpisode.dateEnd !== episode.dateEnd;
  if (needToSendEmailEpisodeUpdate) {
    await Promise.all(
      emails.map(email =>
        sendEmail(queryEmailUpdate, {
          id: episode.id,
          email,
          episodeNumber: previousEpisode.number.toString(),
          episodeOldDate: formatDate(previousEpisode.dateStart),
          episodeNewDate: formatDate(episode.dateStart),
          classLevel:
            translation.resources.class.levels[episodeDetails.pack.level],
          className: episodeDetails.pack.name,
          establishmentName: episodeDetails.establishment.name,
          establishmentCity: episodeDetails.establishment.city,
          regionManager: `${episodeDetails.regionManager.firstName} ${episodeDetails.regionManager.lastName}`,
        }),
      ),
    );
  }
};

export default sendMailsEpisodeUpdate;
