import jsonExport from 'jsonexport';
import i18nProvider from '../../translation';

export const speakerEvaluation = records => {
  return jsonExport(renameValueEvaluation(records), {
    rename: [
      'Thématique choisie',
      i18nProvider.translate(`resources.speakerEvaluation.fields.sameTeacher`),
      i18nProvider.translate(`resources.speakerEvaluation.fields.enoughTime`),
      i18nProvider.translate(`resources.speakerEvaluation.fields.startOnTime`),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.helpFacilitateSession`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.studentAttention`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.studentInvolvement`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.resolutionOrChallenge`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.resolutionOrChallengeHolding`,
      ),
      i18nProvider.translate(`resources.speakerEvaluation.fields.computer`),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.videoProjector`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.technicalIssueEJ`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.technicalIssueRoom`,
      ),
      i18nProvider.translate(
        `resources.speakerEvaluation.fields.overallImpression`,
      ),
      i18nProvider.translate(`resources.speakerEvaluation.fields.comments`),
    ],
    rowDelimiter: ';',
    undefinedString: '0',
  });
};

const renameValueEvaluation = (records: [any]) => {
  return records.map(evalData => {
    return {
      pathChosen: evalData.pathChosen,
      enoughTime: evalData.enoughTime,
      startOnTime: evalData.startOnTime,
      sameTeacher: evalData.sameTeacher,
      helpFacilitateSession: evalData.helpFacilitateSession,
      studentAttention: evalData.studentAttention,
      studentInvolvement: evalData.studentInvolvement,
      resolutionOrChallenge: evalData.resolutionOrChallenge,
      resolutionOrChallengeHolding: evalData.resolutionOrChallengeHolding,
      computer: evalData.computer,
      videoProjector: evalData.videoProjector,
      technicalIssueEJ: evalData.technicalIssueEJ,
      technicalIssueRoom: evalData.technicalIssueRoom,
      overallImpression: evalData.overallImpression,
      comments: evalData.comments,
    };
  });
};
