type RequiredProperty<T> = { [P in keyof T]: Required<NonNullable<T[P]>> };

export const omitUndefinedValues = <T>(
  obj: { [P in keyof T]?: T[P] },
): RequiredProperty<T> => {
  return ((Object.keys(obj) as unknown) as (keyof T)[])
    .filter(key => obj[key] !== undefined)
    .reduce(
      (agg, key) => ({ ...agg, [key]: obj[key] }),
      {} as RequiredProperty<T>,
    );
};

export default { omitUndefinedValues };
