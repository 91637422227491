import jsonExport from 'jsonexport';
import { downloadCSV } from 'react-admin';
import { getRegionRattachement, isOperator } from 'technical/auth/services';

export async function exporter(records): Promise<string> {
  return jsonExport(records, {
    rowDelimiter: ';',
    undefinedString: '0',
  });
}

export async function exportIntervenants(records, roles): Promise<string> {
  const addExpertExeterneColumn = isOperator(roles);
  const currentUserRegion = getRegionRattachement();

  const sensMobiliteCalcul = record => {
    const isFromAnotherRegion = record.regionId !== currentUserRegion;
    const hasMultipleRegions = record.has_multiple_region;
    if (isFromAnotherRegion) {
      return 'Entrant';
    }
    if (hasMultipleRegions) {
      return 'Sortant';
    }
    return '';
  };

  const toExport = records.map(record => ({
    ...record,
    interventionsHorsRR: record.has_multiple_region ? 'Oui' : 'Non',
  }));
  toExport.forEach(record => {
    if (addExpertExeterneColumn) {
      // eslint-disable-next-line no-param-reassign
      record.sensMobilite = sensMobiliteCalcul(record);
      // eslint-disable-next-line no-param-reassign
      record.expertExterne =
        record.type === 'expert' && record.regionId !== currentUserRegion
          ? 'E'
          : '';
    }
    // eslint-disable-next-line no-param-reassign
    delete record.has_multiple_region;
    // eslint-disable-next-line no-param-reassign
    delete record.region_animation_count;
  });
  return jsonExport(toExport, {
    rowDelimiter: ';',
    undefinedString: '0',
  });
}

export const downloadCSVForExcel = (csv, name) => {
  return downloadCSV(`\ufeff ${csv}`, name);
};

export const importExportLevels = {
  CM1: 'CM1',
  CM2: 'CM2',
  '6': '6',
  '5': '5',
  '4': '4',
  '3': '3',
  LYCEE: 'highschool',
};

export const importExportTypeClasses = {
  CLASSIQUE: 'classical',
  SEGPA: 'segpa',
  PREPA_METIER: 'job_prepa',
  SECONDE_PRO: 'second_pro',
  CAP: 'cap',
  ULIS: 'ulis',
  UPE2A: 'upe2a',
  CAP_ORIENTATION: 'cap_orientation',
};

export type Episode = {
  id: string;
  dateStart: Date;
};

export type Pack = {
  id: string;
  name: string;
  level: string;
  establishmentId: string;
  teacherId: string;
  publicationDate: Date;
  type: string;
};
export type Contact = {
  firstName: string;
  lastName: string;
};
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  site: Site;
};

export type Site = {
  organisationName: string;
};

export type ExportableContact = {
  fullname: string;
  email: string;
  phone: string;
  organisationName: string;
};
//
export type Exportable = {
  dateStart: string;
  className: string;
  type: string;
  episodeIndex: string;
  published: boolean;
  expert: ExportableContact;
  expertExterne: string;
  firstAnimateur: ExportableContact;
  secondAnimateur: ExportableContact;
  region: {
    name: string;
  };
  episodes: {
    0: {
      dateStart: string;
    };
    1: {
      dateStart: string;
    };
    2: {
      dateStart: string;
    };
  };
  establishment: {
    id: string;
    category: string;
    name: string;
    academy: string;
    address: string;
    city: string;
    postalCode: string;
    type: string;
    isEducationalCity: string;
    phone: string;
    establishmentReferent: string;
  };
  teacher: {
    fullname: string;
  };
  room: string;
  firstGuest: string;
  secondGuest: string;
};

export type SpeakerEvaluationExportable = {
  region: {
    name: string;
  };
  volunteer: {
    lastName: string;
    firstName: string;
    origin: string;
    company: string;
    type: string;
  };
  establishment: {
    name: string;
    city: string;
    postalCode: string;
    academy: string;
    category: string;
  };
  class: {
    name: string;
    level: string;
    type: string;
  };
  episode: {
    number: string;
  };
  pathChosen: string;
  enoughTime: string;
  startOnTime: string;
  sameTeacher: string;
  helpFacilitateSession: string;
  studentAttention: string;
  studentInvolvement: string;
  resolutionOrChallenge: string;
  resolutionOrChallengeHolding: string;
  computer: string;
  videoprojector: string;
  technicalIssueEJ: string;
  technicalIssueRoom: string;
  overallImpression: string;
  comments: string;
};

export const extractFullname = function extractFullname(
  obj: User | Contact,
): string {
  return `${obj.lastName ? obj.lastName : ''} ${
    obj.firstName ? obj.firstName : ''
  }`.trim();
};

export const extractUser = function extractUser(
  user?: User,
): ExportableContact {
  if (!user) {
    return {
      fullname: '',
      email: '',
      phone: '',
      organisationName: '',
    };
  }
  return {
    fullname: extractFullname(user),
    email: user.email ? user.email : '',
    phone: user.phone ? `="${user.phone}"` : '',
    organisationName: user.site?.organisationName
      ? user.site.organisationName
      : '',
  };
};

export const getEstablishmentName = function getEstablishmentName(
  establishment,
) {
  if (establishment.name) {
    if (establishment.postalCode) {
      return `${establishment.name} - ${establishment.postalCode}`;
    }
    return establishment.name;
  }
  return '';
};
