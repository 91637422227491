import moment from 'moment';
import 'moment/locale/fr';
import { EpisodeDetails, queryEmailUnregister } from './typeDefs';
import sendEmail from '../sendEmail';
import formatDate from '../../../formatDate/formatDate';

moment.locale('fr');

const sendMailsUnregistred = async (
  episode: any,
  episodeDetails: EpisodeDetails,
  emails: string[],
) => {
  // Envoi des mails de desinscription aux volontaires qui ont été retirés de l'épisode.
  if (emails.length > 0) {
    await Promise.all(
      emails.map(email =>
        sendEmail(queryEmailUnregister, {
          id: episode.id,
          recipient: email,
          episodeNumber: episode.number,
          episodeDate: formatDate(episode.dateStart),
          className: episodeDetails.pack.name,
          establishmentName: episodeDetails.establishment.name,
          regionManager: `${episodeDetails.regionManager.firstName} ${episodeDetails.regionManager.lastName}`,
        }),
      ),
    );
  }
};

export default sendMailsUnregistred;
