import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useNotify, useRedirect } from 'react-admin';
import dataProvider from 'technical/api/dataProvider';
import logger from 'technical/logger';
import sendMailsRegistred from 'technical/api/hooks/episodeUpdate/sendMailsRegistred';
import config from '../config';
import { getHighestRole, getRoles } from '../technical/auth/services';
import createUUID from './createUuid';

type Value = Array<null | undefined | string>;
function getDate(dateEp: string | undefined, timeEp: string | undefined) {
  const date = dateEp ? new Date(dateEp) : undefined;
  const time = timeEp ? new Date(timeEp) : undefined;
  // this way of making the date start and end start account for saving hours
  if (date && time) {
    date?.setHours(time?.getHours());
    date?.setMinutes(time?.getMinutes());
    date?.setSeconds(time?.getSeconds());
  }
  return date && time ? date.toISOString() : undefined;
}

const MutationUpdateEpisodes = async function MutationUpdateEpisodes(
  episodesIds,
  formStateValues,
  packId,
) {
  let [
    dateStartEp1,
    dateEndEp1,
    teacherIdEp1,
    expertIdEp1,
    firstAnimateurIdEp1,
    classroomNumberEp1,
    // secondAnimateurIdEp1,
    dateStartEp2,
    dateEndEp2,
    teacherIdEp2,
    expertIdEp2,
    firstAnimateurIdEp2,
    classroomNumberEp2,
    // secondAnimateurIdEp2,
    dateStartEp3,
    dateEndEp3,
    teacherIdEp3,
    expertIdEp3,
    firstAnimateurIdEp3,
    classroomNumberEp3,
  ]: // secondAnimateurIdEp3,
  Value = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ];

  const dateEp1 = formStateValues?.dateEp1 ?? undefined;
  const timeStartEp1 = formStateValues?.timeStartEp1 ?? undefined;
  const timeEndEp1 = formStateValues?.timeEndEp1 ?? undefined;
  const dateEp2 = formStateValues?.dateEp2 ?? undefined;
  const timeStartEp2 = formStateValues?.timeStartEp2 ?? undefined;
  const timeEndEp2 = formStateValues?.timeEndEp2 ?? undefined;
  const dateEp3 = formStateValues?.dateEp3 ?? undefined;
  const timeStartEp3 = formStateValues?.timeStartEp3 ?? undefined;
  const timeEndEp3 = formStateValues?.timeEndEp3 ?? undefined;

  [
    dateStartEp1,
    dateEndEp1,
    teacherIdEp1,
    expertIdEp1,
    firstAnimateurIdEp1,
    classroomNumberEp1,
    // secondAnimateurIdEp1,
    dateStartEp2,
    dateEndEp2,
    teacherIdEp2,
    expertIdEp2,
    firstAnimateurIdEp2,
    classroomNumberEp2,
    // secondAnimateurIdEp2,
    dateStartEp3,
    dateEndEp3,
    teacherIdEp3,
    expertIdEp3,
    firstAnimateurIdEp3,
    classroomNumberEp3,
    // secondAnimateurIdEp3,
  ] = [
    !formStateValues?.unplanifyEp1 ? getDate(dateEp1, timeStartEp1) : null,
    !formStateValues?.unplanifyEp1 ? getDate(dateEp1, timeEndEp1) : null,
    formStateValues?.teacherIdEp1 ?? undefined,
    formStateValues?.expertIdEp1 ?? undefined,
    formStateValues?.firstAnimateurIdEp1 ?? undefined,
    formStateValues?.classroomNumberEp1 ?? undefined,
    // formStateValues?.secondAnimateurIdEp1 ?? undefined,
    !formStateValues?.unplanifyEp2 ? getDate(dateEp2, timeStartEp2) : null,
    !formStateValues?.unplanifyEp2 ? getDate(dateEp2, timeEndEp2) : null,
    formStateValues?.teacherIdEp2 ?? undefined,
    formStateValues?.expertIdEp2 ?? undefined,
    formStateValues?.firstAnimateurIdEp2 ?? undefined,
    formStateValues?.classroomNumberEp2 ?? undefined,
    // formStateValues?.secondAnimateurIdEp2 ?? undefined,
    !formStateValues?.unplanifyEp3 ? getDate(dateEp3, timeStartEp3) : null,
    !formStateValues?.unplanifyEp3 ? getDate(dateEp3, timeEndEp3) : null,
    formStateValues?.teacherIdEp3 ?? undefined,
    formStateValues?.expertIdEp3 ?? undefined,
    formStateValues?.firstAnimateurIdEp3 ?? undefined,
    formStateValues?.classroomNumberEp3 ?? undefined,
    // formStateValues?.secondAnimateurIdEp3 ?? undefined,
  ];

  const query = `mutation MutationUpdateEpisodes(
      $packId: uuid!,
      $episode1Id: uuid,
      $dateStartEp1 : timestamptz,
      $dateEndEp1 :timestamptz,
      $teacherIdEp1 : uuid,
      $expertIdEp1 : uuid,
      $firstAnimateurIdEp1 : uuid,
      # $secondAnimateurIdEp1 : uuid,
      $classroomNumberEp1: String,
      $episode2Id: uuid,
      $dateStartEp2 : timestamptz,
      $dateEndEp2 :timestamptz, 
      $teacherIdEp2 : uuid,
      $expertIdEp2 : uuid,
      $firstAnimateurIdEp2 : uuid,
      # $secondAnimateurIdEp2 : uuid,
      $classroomNumberEp2: String,
      $episode3Id: uuid
      $dateStartEp3 : timestamptz,
      $dateEndEp3 :timestamptz, 
      $teacherIdEp3 : uuid,
      $expertIdEp3 : uuid,
      $firstAnimateurIdEp3 : uuid,
      # $secondAnimateurIdEp3 : uuid,
      $classroomNumberEp3: String,
      ) {
      insert_episode(
        objects : [
        {
          id: $episode1Id,
          classId : $packId,
          dateStart: $dateStartEp1,
          dateEnd : $dateEndEp1,
          teacherId: $teacherIdEp1,
          expertId:$expertIdEp1 , 
          firstAnimateurId: $firstAnimateurIdEp1,
          # secondAnimateurId: $secondAnimateurIdEp1,
          number:1,
          classroomNumber: $classroomNumberEp1
        },
        {
          id: $episode2Id
          classId : $packId,
          dateStart: $dateStartEp2,
          dateEnd : $dateEndEp2,
          teacherId: $teacherIdEp2,
          expertId:$expertIdEp2,
          firstAnimateurId: $firstAnimateurIdEp2, 
          # secondAnimateurId: $secondAnimateurIdEp2,
          number:2,
          classroomNumber: $classroomNumberEp2
        },
        {
          id: $episode3Id
          classId : $packId,
          dateStart: $dateStartEp3,
          dateEnd : $dateEndEp3,
          teacherId: $teacherIdEp3,
          expertId:$expertIdEp3,
          firstAnimateurId: $firstAnimateurIdEp3, 
          # secondAnimateurId: $secondAnimateurIdEp3,
          number:3,
          classroomNumber: $classroomNumberEp3
        }
      ],
      on_conflict : {
        constraint:episode_pkey,
        update_columns: [
          dateStart,
          dateEnd,
          expertId,
          firstAnimateurId,
          teacherId,
          # secondAnimateurId,
          number,
          classroomNumber
        ]
      }
    ) {
    returning {
      id
      animateur_1 {email}
      expert {email}
    }
  }
    }
  `;

  const variables = {
    packId,
    episode1Id: episodesIds.length > 0 ? episodesIds[0] : createUUID(),
    dateStartEp1,
    dateEndEp1,
    teacherIdEp1,
    expertIdEp1,
    firstAnimateurIdEp1,
    // secondAnimateurIdEp1,
    classroomNumberEp1,
    episode2Id: episodesIds.length > 0 ? episodesIds[1] : createUUID(),
    dateStartEp2,
    dateEndEp2,
    teacherIdEp2,
    expertIdEp2,
    firstAnimateurIdEp2,
    // secondAnimateurIdEp2,
    classroomNumberEp2,
    episode3Id: episodesIds.length > 0 ? episodesIds[2] : createUUID(),
    dateStartEp3,
    dateEndEp3,
    teacherIdEp3,
    expertIdEp3,
    firstAnimateurIdEp3,
    // secondAnimateurIdEp3,
    classroomNumberEp3,
  };

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

const SaveMultipleEpisodesButton = props => {
  const formState = useFormState();
  const { episodesIds, record, episodes, ...otherProps } = props;
  const packId = record.id;
  const redirectTo = useRedirect();
  const notify = useNotify();

  const handleClick = useCallback(() => {
    if (!formState.valid) {
      const { errors } = formState;
      Object.keys(errors).forEach(
        error => notify(`${errors[error][0]}`, 'warning'),
        [],
      );
      return;
    }

    MutationUpdateEpisodes(episodesIds, formState.values, packId)
      .then(response => response.json())
      .then(data => {
        try {
          const dataProviderPayload: any[] = [];
          // For Each EpisodeID we get we're going to create a Payload for the Hooks
          // Usually the Hooks is trigger by ReactAdmin but here as it's a custom entry in Hasura
          // We want to trigger the Hooks as if we were ReactAdmin
          // CAS UPDATE
          if (episodesIds.length > 0) {
            episodesIds.forEach((episodeId: string, key: number) => {
              // We get parameters as : timeEndEp2
              // So to access them we use :
              const access = `Ep${key + 1}`;
              const dateEp = formState?.values[`date${access}`] ?? undefined;
              const timeStartEp =
                formState?.values[`timeStart${access}`] ?? undefined;
              const timeEndEp =
                formState?.values[`timeEnd${access}`] ?? undefined;
              const dateStart = !formState?.values[`unplanify${access}`]
                ? getDate(dateEp, timeStartEp)
                : null;
              const dateEnd = !formState?.values[`unplanify${access}`]
                ? getDate(dateEp, timeEndEp)
                : null;
              dataProviderPayload.push({
                id: episodeId, // Episode Id used by the requestProvider to understand what it touch
                // New Data in already set in Hasura
                data: {
                  id: episodeId,
                  number: episodes[key].number,
                  dateEnd,
                  dateStart,
                  expertId: formState.values[`expertId${access}`],
                  teacherId: formState.values[`teacherId${access}`],
                  firstAnimateurId:
                    formState.values[`firstAnimateurId${access}`],
                  // secondAnimateurId:
                  //   formState.values[`secondAnimateurId${access}`],
                  classroomNumber: formState.values[`classroomNumber${access}`],
                  classId: packId,
                },
                // Old Data retrieved before the update
                previousData: episodes[key],
              });
            });
            // For each payload we trigger the dataProvider
            dataProviderPayload.forEach(payload => {
              dataProvider('UPDATE', 'episode', payload);
            });
          } else {
            // CAS CREATE permettant la gestion de l'envoie de mail de confirmation d inscription
            data.data.insert_episode.returning.forEach(e => {
              // Récupérer les emails non nuls et non undefined
              const emails = [e.animateur_1?.email, e.expert?.email].filter(
                email => email,
              );

              // Envoyer les emails seulement s'il y en a au moins un
              if (emails.length > 0) {
                sendMailsRegistred(e.id, emails);
              }
            });
          }
        } catch (error) {
          logger.warn(error);
        }
        notify('episodesSavedWithSuccess');
        redirectTo('/class');
      })
      .catch(() => {
        notify('episodesNotSaved');
      });
  }, [formState, notify, redirectTo, episodesIds, packId, episodes]);

  return (
    <SaveButton
      {...otherProps}
      label="Enregistrer les épisodes"
      handleSubmitWithRedirect={handleClick}
    />
  );
};

export default SaveMultipleEpisodesButton;
