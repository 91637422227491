export interface EmailUpdatePayload {
  id: string;
  email: string;
  episodeNumber: string;
  episodeOldDate: string;
  episodeNewDate: string;
  classLevel: string;
  className: string;
  establishmentName: string;
  establishmentCity: string;
  regionManager: string;
}

export interface EmailUpdatePeoplePayload {
  id: string;
  email: string;
  classLevel: string;
  className: string;
  classType: string;
  classTheme: string;
  episodeNumber: string;
  classroomNumber: string;
  episodeHour: string;
  episodeDate: string;
  establishmentName: string;
  establishmentCity: string;
  teacherName?: string;
  firstAnimatorName?: string;
  firstAnimatorPhone?: string;
  secondAnimatorName?: string;
  secondAnimatorPhone?: string;
  expertName?: string;
  expertPhone?: string;
  regionManager: string;
}

export interface EmailEpisodeUnregisterPayload {
  id: string;
  recipient: string;
  episodeNumber: number;
  className: string;
  establishmentName: string;
}

export interface EmailEpisodeRegisterPayload {
  id: string;
  recipient: string;
}

export const queryEmailUpdate = `mutation Email (
    $id: String!
    $email: String!
    $episodeNewDate: String!
    $episodeNumber: String!
    $episodeOldDate: String!
    $establishmentCity: String!
    $establishmentName: String!
    $classLevel: String!
    $className: String!
    $regionManager: String!
  ) {
    episodeUpdateEmail(input: {
      id: $id
      email: $email
      episodeNewDate: $episodeNewDate
      episodeNumber: $episodeNumber
      episodeOldDate: $episodeOldDate
      establishmentCity: $establishmentCity
      establishmentName: $establishmentName
      className: $className
      classLevel: $classLevel
      regionManager: $regionManager
    }) {
      success
    }
  }`;

export const queryEmailPeopleUpdate = `mutation Email (
    $id: String!
    $email: String!
    $classLevel: String!
    $classroomNumber: String!
    $className: String!
    $classType: String!
    $classTheme: String!
    $episodeNumber: String!
    $episodeHour: String!
    $episodeDate: String!
    $establishmentName: String!
    $establishmentCity: String!
    $teacherName: String
    $firstAnimatorName: String
    $firstAnimatorPhone: String
    $secondAnimatorName: String
    $secondAnimatorPhone: String
    $expertName: String
    $expertPhone: String
    $regionManager: String!
  ) {
    episodeUpdatePeopleEmail(input: {
      id: $id
      email: $email
      className: $className
      classLevel: $classLevel
      classType: $classType
      classTheme: $classTheme
      classroomNumber: $classroomNumber
      episodeNumber: $episodeNumber
      episodeHour: $episodeHour
      episodeDate: $episodeDate
      establishmentName: $establishmentName
      establishmentCity: $establishmentCity
      teacherName: $teacherName
      firstAnimatorName: $firstAnimatorName
      firstAnimatorPhone: $firstAnimatorPhone
      secondAnimatorName: $secondAnimatorName
      secondAnimatorPhone: $secondAnimatorPhone
      expertName: $expertName
      expertPhone: $expertPhone
      regionManager: $regionManager
    }) {
      success
    }
  }`;

export const queryEmailUnregister = `mutation Email (
  $id: String!
  $recipient: String!
  $episodeNumber: Int!
  $className: String!
  $establishmentName: String!
  $episodeDate: String!
  $regionManager: String!
  ) {
    episodeUnregisterEmail(
      input: {
      id: $id
      recipient: $recipient
      episodeNumber: $episodeNumber
      className: $className
      establishmentName: $establishmentName
      episodeDate: $episodeDate
      regionManager: $regionManager
    }
  ) {
      success
    }
  }
`;

export const queryEmailRegister = `mutation Email (
  $id: String!
  $recipient: String!
  ) {
    episodeRegisterEmail(
      input: {
      id: $id
      recipient: $recipient
    }
  ) {
      success
    }
  }
`;

export interface EpisodeDetails {
  establishment: any;
  region: any;
  regionManager: any;
  teacher: any;
  pack: any;
}

export interface VolontairesEpisode {
  firstAnimateur: any;
  secondAnimateur: any;
  expert: any;
}
