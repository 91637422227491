import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  SelectInput,
  minLength,
  email,
  List,
  Datagrid,
  EmailField,
  TextField,
  Filter,
  Show,
  SimpleShowLayout,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import {
  additionalShowProps,
  getRoles,
  isDataManager,
  isShowOnlyRoles,
} from 'technical/auth/services';
import RegionFilter from 'components/RegionFilter';
import civilityChoices from '../../components/CivilityChoices';
import TranslateField from '../../components/translateField';
import PartialSearchInput from '../../components/PartialSearchInput';
import { exporter, downloadCSVForExcel } from '../../components';
import ListActions from '../../components/ListActions';

const validateName = [minLength(3), required()];
const validateEmail = [email()];
const roles = getRoles();

const ContactForm = props => {
  const { record } = props;
  return (
    <SimpleForm {...props} redirect="list">
      <SelectInput
        source="civility"
        choices={civilityChoices}
        validate={required()}
      />
      <TextInput source="firstName" validate={validateName} />
      <TextInput source="lastName" validate={validateName} />
      <TextInput source="email" validate={validateEmail} />
      <TextInput source="phone" />
      {(isDataManager(roles) || Object.keys(record).length !== 0) && (
        <ReferenceInput
          source="regionId"
          reference="region"
          filterToQuery={searchText => ({
            name: { _ilike: `%${searchText}%` },
          })}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText="name"
            options={{ autoComplete: 'no' }}
          />
        </ReferenceInput>
      )}
    </SimpleForm>
  );
};

export const ContactEdit = props => (
  <Edit {...props}>
    <ContactForm />
  </Edit>
);
export const ContactCreate = props => (
  <Create {...props}>
    <ContactForm />
  </Create>
);
const ContactFilters = props => (
  <Filter {...props}>
    <PartialSearchInput source="firstName" />
    <PartialSearchInput source="lastName" />
    <PartialSearchInput source="email" />
    <RegionFilter label="Région" source="regionId" />
  </Filter>
);

export const ContactList = props => (
  <List
    {...props}
    filters={<ContactFilters />}
    sort={{ field: 'lastName', order: 'ASC' }}
    {...(isShowOnlyRoles(roles) ? { bulkActionButtons: false } : {})}
    actions={<ListActions />}
    exporter={records =>
      exporter(records).then(csv => downloadCSVForExcel(csv, 'actions'))
    }
  >
    <Datagrid rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'}>
      <TranslateField namespace="user.civility" source="civility" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="phone" />
      <ReferenceField source="regionId" reference="region">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ContactShow = props => (
  <Show {...props} {...additionalShowProps(roles)}>
    <SimpleShowLayout>
      <TranslateField namespace="user.civility" source="civility" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="phone" />
    </SimpleShowLayout>
  </Show>
);
