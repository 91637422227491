import logger from 'technical/logger';
import config from 'config';
import hasuraDataProvider from 'ra-data-hasura';
import { fetchUtils, HttpError } from 'react-admin';
import { renewToken } from 'technical/auth/authProvider';
import { getHighestRole, getRoles } from '../auth/services';
import hooks from './hooks';
import { getOne } from './hooks/episodeUpdate/episodeUpdate';
import { userUpdate } from './hooks/userUpdate/userUpdate';
import { enhancedDeleteEpisode } from './hooks/episodeDelete';

type HttpOption = {
  headers?: Headers;
};

const withCustomDeleteEpisode = requestHandler => {
  return (type, resource, params) => {
    if (type === 'DELETE' && resource === 'episode') {
      return enhancedDeleteEpisode(params);
    }
    return requestHandler(type, resource, params);
  };
};
interface HttpError extends Error {
  body: any;
  status: number;
}

const httpClient = (url: string, options: HttpOption = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ 'Content-Type': 'application/json' });
  }

  const authorization = `Bearer ${localStorage.getItem('token')}`;
  options.headers.set('authorization', authorization);
  options.headers.set('X-Hasura-Role', getHighestRole(getRoles()));

  return fetchUtils.fetchJson(url, options);
};

export function getUnchangedFields({
  data,
  previousData = {},
}: {
  // data and previousData are a snapshot of the resource
  // not genericly typing it as ra-data-hasura is not typed
  data: any;
  previousData: any;
}) {
  return Object.entries(data)
    .filter(([key, value]) => previousData[key] !== value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}

function computeFinalParams(type: any, params: any) {
  if (type === 'UPDATE') {
    const newData = getUnchangedFields(params);

    return {
      ...params,
      // Simulating a PATCH instead of PUT top preserve column-based authentication = some fields cannot be updated
      // id MUST be set - if
      data: newData,
    };
  }
  return params;
}
const enhancedUpdateToPatch = (requestHandler: any) => (
  // ra-data-hasura is not typed
  type: any,
  resource: any,
  params: any,
) => {
  const finalParams = computeFinalParams(type, params);
  if (finalParams.data && Object.keys(finalParams.data).length === 0) {
    // Hasura will get an error if trying to update a resource without setting any field. Not calling API on this case
    logger.info('Unchanged data. Not calling API');
    // https://github.com/marmelab/react-admin/blob/master/docs/DataProviders.md#response-format
    return Promise.resolve({ data: params.previousData, total: 1 });
  }
  return requestHandler(type, resource, finalParams).catch((err: HttpError) => {
    // jwt token expired -> renewing
    if (err.status === 400 && err.body.code === 'invalid-jwt') {
      return renewToken().then(() =>
        requestHandler(type, resource, finalParams),
      );
    }
    // Uncomment this if you want to auto display the error message from the API
    // const error = new HttpError(err.body.error);
    // error.body = err.body;
    // error.status = err.status;
    // throw error;

    throw err;
  });
};

const withHooks = (requestHandler: any) => async (
  // ra-data-hasura is not typed
  type: any,
  resource: any,
  params: any,
) => {
  const hook = hooks[resource] ? hooks[resource][type] : undefined;

  // In case it's a delete many, we retrieve the records before sending the requestHandler because
  // If we don't the records are deleted before we can retrieve them
  if (type === 'DELETE_MANY' && resource === 'episode') {
    const records = await Promise.all(
      params.ids.map(id => getOne('episode', { id }, requestHandler)),
    );
    hook(records, requestHandler);
    return requestHandler(type, resource, params);
  }
  if (type === 'DELETE_MANY' && resource === 'class') {
    const classes = await Promise.all(
      params.ids.map(id => getOne('class', { id }, requestHandler)),
    );
    await hook(classes, requestHandler);
    return requestHandler(type, resource, params);
  }
  if (type === 'UPDATE' && resource === 'user') {
    if (
      params.previousData &&
      params.previousData.region_animation_ids &&
      params.data.region_animation_ids &&
      params.data.regionId
    ) {
      const currentRegionAnimationIds = params.data.region_animation_ids;
      const previousRegionAnimationIds =
        params.previousData.region_animation_ids;
      const createdRegionIds = currentRegionAnimationIds.filter(
        x => !previousRegionAnimationIds.includes(x),
      );
      const deletedRegionIds = previousRegionAnimationIds.filter(
        x => !currentRegionAnimationIds.includes(x),
      );
      const createParamNames = createdRegionIds.map(
        (_, i) => `$createRegionId${i}`,
      );
      const deleteParamNames = deletedRegionIds.map(
        (_, i) => `$deleteRegionId${i}`,
      );
      const deleteRequestAliases = deletedRegionIds.map(
        (_, i) => `delete_user_region_animation${i}`,
      );

      let mutation = `mutation M(${[...createParamNames, ...deleteParamNames]
        .map(s => `${s}: uuid!`)
        .join(', ')}, $userId: uuid!) {`;
      deletedRegionIds.forEach((_, i) => {
        mutation += `${deleteRequestAliases[i]}: delete_user_region_animation(where: {region_id:  {_eq: ${deleteParamNames[i]}}, user_id:  {_eq: $userId}}){`;
        mutation += `affected_rows`;
        mutation += `}`;
      });
      mutation += `insert_user_region_animation(objects: [`;
      createdRegionIds.forEach((_, i) => {
        mutation += `{region_id: ${createParamNames[i]}, user_id: $userId},`;
      });
      mutation += `]){affected_rows}}`;

      const variables: any = {};

      createParamNames.forEach((param, i) => {
        variables[param.substring(1)] = createdRegionIds[i];
      });
      deleteParamNames.forEach((param, i) => {
        variables[param.substring(1)] = deletedRegionIds[i];
      });
      variables.userId = params.id;

      fetch(`${config.graphqlUri}/v1/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Hasura-Role': getHighestRole(getRoles()),
        },
        body: JSON.stringify({ query: mutation, variables }),
      }).catch(error => {
        console.error(error);
      });

      // Gestion des mails
      userUpdate(
        params.previousData,
        params.data,
        deletedRegionIds,
        createdRegionIds,
      );

      // eslint-disable-next-line no-param-reassign
      delete params.data.region_animation_ids;
      // eslint-disable-next-line no-param-reassign
      delete params.previousData.region_animation_ids;
    }
  }
  return requestHandler(type, resource, params).then(async res => {
    if (hook) {
      // Case for update episode where we need to compare the previous data with the next
      if (type === 'UPDATE' && resource === 'episode') {
        hook(
          params.previousData,
          { ...params.previousData, ...res.data },
          requestHandler,
        );
      } else if (type === 'DELETE_MANY' && resource === 'episode') {
        hook({ ...params.previousData, ...res.data }, requestHandler);
      } else if (type === 'UPDATE' && resource === 'user') {
        hook(
          params.previousData,
          { ...params.previousData, ...res.data },
          params.id,
          requestHandler,
        );
      } else if (type === 'GET_ONE' && resource === 'user') {
        await hook(res, requestHandler);
      } else {
        hook({ ...params.previousData, ...res.data }, requestHandler);
      }
    }
    return res;
  });
};

const withMappedResource = (requestHandler: any) => (
  // ra-data-hasura is not typed
  type: any,
  resource: any,
  params: any,
) => {
  const classResource =
    type === 'GET_LIST' ||
    type === 'GET_MANY' ||
    type === 'GET_MANY_REFERENCE' ||
    type === 'GET_ONE'
      ? 'class_vue'
      : 'class';
  const episodeResource =
    type === 'GET_LIST' ||
    type === 'GET_MANY' ||
    type === 'GET_MANY_REFERENCE' ||
    type === 'GET_ONE'
      ? 'episode_vue'
      : 'episode';
  const map = {
    class: classResource,
    episode: episodeResource,
    episode_to_fill: episodeResource,
    episode_filled_to_come: episodeResource,
    episode_to_plan: episodeResource,
    animator: type === 'GET_LIST' ? 'user_formation_status' : 'user',
    operator: 'user',
    training_to_validate: 'training',
    training_to_plan: 'training',
    speakerEvaluation: 'speakerEvaluation2425',
  };
  return requestHandler(type, map[resource] ? map[resource] : resource, params);
};

export default withMappedResource(
  withHooks(
    enhancedUpdateToPatch(
      withCustomDeleteEpisode(
        hasuraDataProvider(config.graphqlUri, httpClient),
      ),
    ),
  ),
);
