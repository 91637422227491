import React from 'react';
import { FunctionField, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const TranslateField = ({ namespace = 'translations', ...props }) => {
  const translate = useTranslate();

  return (
    <FunctionField
      {...props}
      render={(record: any, source: string) => {
        const value = get(record, source);
        if (
          namespace.includes('resources.speakerEvaluation.answers') ||
          namespace === 'evaluation'
        ) {
          return translate(`${namespace}.${value}`);
        }

        return value ? translate(`${namespace}.${value}`) : '';
      }}
    />
  );
};

TranslateField.propTypes = {
  namespace: PropTypes.string,
  addLabel: PropTypes.bool,
};

TranslateField.defaultProps = {
  namespace: 'translations',
  addLabel: true,
};

export default TranslateField;
