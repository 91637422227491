import React from 'react';
import { SelectInput } from 'react-admin';

const StatusFilterInput = props => {
  const choices = [
    { id: '%Jamais formé%', name: 'Jamais formé' },
    { id: '%Non formé cette année%', name: 'Non formé cette année' },
    { id: '%Formé le%', name: 'Formé cette année' },
    { id: '%Inscrit à une formation%', name: 'Inscrit à une formation' },
  ];

  return (
    <SelectInput
      choices={choices}
      source="formationStatus._ilike"
      label="resources.user_formation_status.formationstatus"
      {...props}
    />
  );
};

export default StatusFilterInput;
