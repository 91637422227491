import englishMessages from 'ra-language-english';

englishMessages.ra.boolean.null = 'Null label';
const id = 'Identifiant';
// const createdAt = 'Créé le';
// const updatedAt = 'Mis à jour le';
// const deletedAt = 'Supprimé le';
const email = 'Email';
const firstName = 'Prénom';
const lastName = 'Nom';
const name = 'Nom';
const establishmentId = 'Etablissement';
const classId = "Pack d'épisode";
const dateStart = 'Du';
const dateEnd = 'Au';
const firstAnimateurId = 'Animateur';
const secondAnimateurId = 'Animateur secondaire';
const expertId = 'Expert';
const firstGuest = 'Premier invité';
const secondGuest = 'Second invité';
const regionId = 'Région';
const nbSeats = 'Nombre de place';
const nbSeatsAvailable = 'Nombre de place restant';
const type = 'Type';
const address = 'Adresse';
const addressSecondary = 'Adresse Secondaire';
const civility = 'Civilité';
const phone = 'Téléphone';
const company = 'Entreprise';
const isActive = 'Actif';
const organisationName = "Nom de l'organisation";
const postalCode = 'Code Postal';
const city = 'Ville';
const origin = 'Origine';
const managerId = 'Responsable';
const referenceAcademy = 'Académie de référence';
const principalId = 'Principal';
const principalAssistantId = 'Principal Adjoint';
const educationAdvisorId = 'CPE';
const principalSegpaId = 'Directeur SEGPA';
const collegeReferentId = 'Référent du collège';
const isEducationalCity = 'Cité éducative';
const category = 'Catégorie';
const publicationDate = 'Date de publication';
const episodes = 'Épisodes';
const addEpisode = 'Ajouter épisode';
const publish = 'Publier';
const unpublish = 'Dépublier';
const importPack = 'Importer un pack';
const theme = 'Thématique';
const animator = 'Intervenant';
const teacherId = 'Enseignant';
const createdAt = 'Demandé le';
const level = 'Niveau';
const number = "Numéro de l'épisode";
const classroomNumber = 'N° de la salle';
const parentsProgram = 'Programme 100% Parents';
const UAICode = 'Code UAI';
const pathChosen = 'Parcours retenu avec l’enseignant pour l’épisode 3';
const enoughTime =
  'Avez-vous eu le temps suffisant pour dérouler 100% de l’épisode ?';
const startOnTime = 'L’épisode a-t-il débuté à l’heure ?';
const sameTeacher =
  "L'enseignant présent était le même que l'épisode(s) précédent";
const helpFacilitateSession = 'Aide à l’animation de la session';
const studentAttention = 'Attention des élèves';
const studentInvolvement =
  'Implication des élèves (participation, réceptivité aux messages)';
const resolutionOrChallenge = 'Formalisation du défi';
const resolutionOrChallengeHolding =
  'Proportion d’élèves qui se souviennent de leur défi';
const computer = 'Quel ordinateur avez-vous utilisé ?';
const videoProjector = 'Quel vidéo projecteur avez-vous utilisé ?';
const technicalIssueMessage =
  'Avez-vous rencontré une défaillance technique du matériel pendant l’intervention ?';
const technicalIssueEJ = `${technicalIssueMessage} (Matériel EJ)`;
const technicalIssueRoom = `${technicalIssueMessage} (Matériel de la salle)`;
const overallImpression = 'Globalement cette session était';
const comments = 'Partagez vos commentaires';

const episode = {
  name: 'Episode |||| Episodes',
  fields: {
    id,
    name,
    classId,
    dateStart,
    dateEnd,
    expertId,
    firstAnimateurId,
    secondAnimateurId,
    firstGuest,
    secondGuest,
    number,
    teacherId,
    classroomNumber,
    expertexterne: 'Expert externe',
  },
  actions: {
    seeSpeakerEvaluations: 'Voir les évaluations des intervenants',
  },
};

const user = {
  name: 'Utilisateur |||| Utilisateurs',
  fields: {
    id,
    email,
    firstName,
    lastName,
    civility,
    phone,
    company,
    isActive,
    type,
    origin,
    regionId,
    formationStatus: "Statut d'habilitation annuelle",
  },
};
const training = {
  name: 'Formation |||| Formations',
  fields: {
    id,
    name,
    address,
    postalCode,
    city,
    dateStart,
    dateEnd,
    regionId,
    nbSeats,
    nbSeatsAvailable,
    type,
    createdAt,
  },
};
export default {
  resources: {
    user,
    user_region_animation: {
      fields: {
        user_id: 'userid',
        region_id: 'regionid',
      },
    },
    animator: {
      ...user,
      fields: {
        ...user.fields,
        regionId: 'Région de rattachement',
        oldRegionId: 'Ancienne région de rattachement',
        changeRegionDate: 'Date de changement de région',
        v: 'Intervenants hors RR',
        region_animation_count: "Nombre de régions d'animation",
        multiple: 'Intervenants hors RR',
      },
      name: 'Intervenant |||| Intervenants',
    },
    operator: {
      ...user,
      name: 'Opérateur |||| Opérateurs',
    },
    establishment: {
      name: 'Etablissement |||| Etablissements',
      fields: {
        id,
        name,
        regionId,
        address,
        addressSecondary,
        postalCode,
        city,
        email,
        phone,
        type,
        referenceAcademy,
        principalId,
        principalAssistantId,
        educationAdvisorId,
        principalSegpaId,
        collegeReferentId,
        isEducationalCity,
        category,
        isActive,
        UAICode,
        isMfrOrRelay: 'Etablissement MFR ou Dispositif Relais',
      },
      actions: {
        importPack,
      },
      success: {
        importPack: "Pack d'épisodes importé avec succès",
      },
      error: {
        importPack:
          "Une erreur est survenue lors de l'import du pack d'épisodes",
        importPackLevel:
          "Niveau invalide à la ligne %{line} colonne A : %{data} n'est pas valide",
        importPackName:
          "Nom invalide à la ligne %{line} colonne B : %{data} n'est pas valide",
        importPackType:
          "Type de classe invalide à la ligne %{line} colonne C : %{data} n'est pas valide",
        importTeacherEmail:
          "Email du professeur invalide à la ligne %{line} colonne N : %{data} n'est pas une adresse e-mail valide",
      },
    },
    class: {
      name: "Pack d'épisode |||| Packs d'épisodes",
      fields: {
        id,
        name: 'Nom de la classe',
        establishmentId,
        publicationDate,
        episodes,
        theme,
        level,
        parentsProgram,
        expertexterne: 'Expert externe',
      },
      actions: {
        add_episode: addEpisode,
        publish,
        unpublish,
      },
      answers: {
        NC: 'Episode Non concerné',
        NSP: 'Ne sait pas',
        T1: 'Thématique 1 - Développer l’esprit collectif',
        T2: 'Thématique 2 - Favoriser le bien être au collège',
        T3a: 'Thématique 3a - Préparer son orientation',
        T3b: 'Thématique 3b - Préparer son orientation / Déclinaison ruralité',
      },
      levels: {
        CM1: 'CM1',
        CM2: 'CM2',
        '6': '6ème',
        '5': '5ème',
        '4': '4ème',
        '3': '3ème',
        highschool: 'Lycée',
      },
      types: {
        classical: 'Classique',
        segpa: 'SEGPA',
        job_prepa: 'Prépa métier',
        second_pro: 'Seconde Pro',
        cap: 'CAP',
        ulis: 'Ulis',
        upe2a: 'Upe2A',
        cap_orientation: 'Cap Orientation',
      },
    },
    episode: {
      fields: {
        ...episode.fields,
      },
      actions: {
        ...episode.actions,
      },
    },
    episode_to_fill: {
      ...episode,
      name: 'Episode à combler |||| Episodes à combler',
    },
    episode_filled_to_come: {
      ...episode,
      name: 'Episode comblé à venir |||| Episodes comblés à venir',
    },
    episode_to_plan: {
      ...episode,
      name: 'Episode à planifier |||| Episodes à planifier',
    },
    training,
    training_to_plan: {
      ...training,
      name: 'Formation à planifier |||| Formations à planifier',
    },
    training_to_validate: {
      ...training,
      name: 'Formation à valider |||| Formations à valider',
    },
    region: {
      name: 'Région |||| Régions',
      fields: {
        id,
        name,
        managerId,
      },
    },
    site: {
      name: 'Site |||| Sites',
      fields: {
        id,
        name,
        regionId,
        address,
        postalCode,
        city,
        origin,
        organisationName,
      },
    },
    contact: {
      name: 'Contact |||| Contacts',
      fields: {
        id,
        firstName,
        lastName,
        email,
        civility,
        phone,
      },
    },
    speakerEvaluation: {
      name: "Evaluation de l'intervenant |||| Evaluations des intervenants",
      fields: {
        pathChosen,
        enoughTime,
        startOnTime,
        sameTeacher,
        helpFacilitateSession,
        studentAttention,
        studentInvolvement,
        resolutionOrChallenge,
        resolutionOrChallengeHolding,
        computer,
        videoProjector,
        technicalIssueEJ,
        technicalIssueRoom,
        overallImpression,
        comments,
        animator,
      },
      answers: {
        enoughTime: {
          Y: 'Oui',
          N: 'Non',
        },
        startOnTime: {
          Y: 'Oui',
          M15M: 'Non avec moins de 15’ de retard',
          P15M: 'Non avec plus de 15’ de retard',
        },
        sameTeacher: {
          Y: 'Oui',
          N: 'Non',
          NSP: 'Ne sait pas',
          NC: 'Épisode non concerné',
        },
        helpFacilitateSession: {
          '5':
            'L’enseignant a eu un rôle actif (animation, appui des messages, aide à l’identification des défis …)',
          '4':
            'L’enseignant est resté en retrait mais a pris la parole aux moments demandés par les intervenants',
          '2': 'L’enseignant a été peu impliqué et n’a pas participé',
          '1':
            'L’enseignant a exprimé (comportement/paroles) devant sa classe son désaccord envers les messages et/ou la venue d’Energie Jeunes',
        },
        studentAttention: {
          '5': 'Classe très attentive',
          '4': 'Classe assez attentive malgré des bavardages',
          '2': 'Classe peu attentive avec des élèves distraits',
          '1': 'Classe pas attentive avec de nombreux élèves perturbateurs',
        },
        studentInvolvement: {
          '5': 'Très bonne',
          '4': 'Bonne',
          '2': 'Faible',
          '1': 'Très faible',
        },
        resolutionOrChallenge: {
          '5':
            'La quasi-totalité des élèves s’est investi dans le choix de son défi',
          '3': 'La moitié des élèves s’est investi dans le choix de son défi',
          '1': 'Peu d’élèves se sont investis dans le choix de son défi',
          NSP: 'Ne sait pas',
          NC: 'Épisode non concerné',
        },
        resolutionOrChallengeHolding: {
          '5': 'Plus de 75%',
          '4': 'Plus de 50%',
          '2': 'Plus de 25%',
          '1': 'Moins de 25%',
          NSP: 'Ne sait pas',
          NC: 'Épisode non concerné',
        },
        computer: {
          '2': 'Celui de la valise Energie Jeunes',
          '1': 'Celui de la salle',
          '0': 'Autre',
        },
        videoProjector: {
          '2': 'Celui d’Energie Jeunes',
          '1': 'Celui de la salle',
        },
        technicalIssueEJ: {
          '1': 'Matériel Energie Jeunes',
          '0': 'Non',
        },
        technicalIssueRoom: {
          '1': 'Matériel de la salle',
          '0': 'Non',
        },
        overallImpression: {
          '5': 'Excellente',
          '4': 'Bonne',
          '3': 'Moyenne',
          '2': 'Peu satisfaisante',
          '1': 'Non satisfaisante',
        },
      },
      export_button_current:
        'Exporter les évaluation intervenants de la saison en cours',
      export_button_previous:
        'Exporter les évaluation intervenants des saisons précédentes',
    },
  },
  notifications: {
    animator:
      "L'utilisateur vient d'obtenir le rôle d'animateur. Si c'est une erreur, veuillez changer son rôle en cliquant sur son nom.",
    expert:
      "L'utilisateur vient d'obtenir le rôle d'expert. Si c'est une erreur, veuillez changer son rôle en cliquant sur son nom.",
  },
  user: {
    types: {
      new: 'Nouveau',
      animator: 'Animateur',
      expert: 'Expert',
      operator: 'Opérateur',
      administrator: 'Administrateur',
      'region-manager': 'Gestionnaire Régional',
      national: 'Profil National',
    },
    origins: {
      'active-in-company': 'Actif en entreprise',
      egee: 'EGEE',
      independent: 'Indépendant',
      'seconded-employee': 'Salarié détaché',
      'ej-employee': 'Employé EJ',
      'civic-service': 'Service civique',
    },
    civility: {
      mr: 'Monsieur',
      mrs: 'Madame',
    },
  },
  training: {
    attendees: {
      id,
      attendee: 'Participant',
      validatedAt: 'Validé le',
      invalidatedAt: 'Invalidé le',
    },
    types: {
      new: 'Nouvelle arrivée',
      expert: 'Devenir expert',
      renewal: 'Renouvellement annuel',
      expert_renewal: 'Renforcement Expert',
      other: 'Autre',
    },
  },
  establishment: {
    categories: {
      rep: 'REP',
      'rep+': 'REP +',
      'no rep': 'Non REP',
    },
    types: {
      primary: 'Primaire',
      secondary: 'Collège',
      highschool: 'Lycée',
    },
  },
  evaluation: {
    3: "Tout à fait d'accord",
    2: "Plutôt d'accord",
    1: "Plutôt pas d'accord",
    0: "Pas du tout d'accord",
    'no-answer': 'Non répondu',
    null: 'Non répondu',
    yes: 'Oui',
    no: 'Non',
    true: 'Oui',
    false: 'Non',
  },
  site: {
    origins: {
      'partner-company': 'Sites entreprises partenaires',
      ej: 'Sites EJ',
      independent: 'Autres sites',
      egee: 'Sites EGEE',
      'civic-service': 'Sites service civique',
    },
  },
  'Bad Request': "Cette opération n'est pas permise",
  episodesSavedWithSuccess: 'Les épisodes sont enregistrés',
  episodesNotSaved:
    "Une erreur est survenue lors de l'enregistrement des épisodes",
};
