import jsonExport from 'jsonexport';
import moment from 'moment';
import i18nProvider from '../../translation';
import { categories, types } from '../establishment/resource';
import config from '../../config';
import { getHighestRole, getRoles } from '../../technical/auth/services';
import {
  Episode,
  Exportable,
  extractFullname,
  extractUser,
  importExportLevels,
} from '../../components/exporter';

const QueryExport = async function QueryExport(episodeIds: string[]) {
  const query = `query Export($episodeIds: [uuid!]) {
  episode (where: {id: {_in: $episodeIds}}, order_by: {classId: asc}){
    dateStart
    number
    firstGuest
    secondGuest
    teacher {
      firstName
      lastName
    }
    expert {
      firstName
      lastName
      email
      phone
      regionId
      site {
        organisationName
      }
    }
    animateur_1 {
      firstName
      lastName
      email
      phone
      site {
        organisationName
      }
    }
    animateur_2 {
      firstName
      lastName
      email
      phone
      site {
        organisationName
      }
    }
    classroomNumber
    class {
      level
      name
      publicationDate
      type
      establishment {
        id
        contactByCollegereferentid {
          firstName
          lastName
        }
        category
        name
        referenceAcademy
        postalCode
        city
        address
        type
          isEducationalCity
        phone
        region {
          name
        }
      }
      episodes (order_by: {dateStart: asc}){
        dateStart
      }
    }
  }
}`;

  const variables = { episodeIds };

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables }),
  });
};

const FlattenEpisode = async function FlattenEpisode(
  episodes: Episode[],
): Promise<Exportable[]> {
  const episodeIds = episodes.map(episode => {
    return episode.id;
  });
  const response = await QueryExport(episodeIds);
  const episodesData = await response.json().then(res => {
    return res.data.episode;
  });

  return episodesData.map(episodeData => {
    const classData = episodeData?.class;
    const establishment = episodeData?.class?.establishment;
    return {
      dateStart: episodeData.dateStart
        ? moment(episodeData.dateStart).format('D/M/YYYY HH:mm')
        : '',
      className: classData.name,
      level: Object.keys(importExportLevels).find(
        key => importExportLevels[key] === classData.level,
      ),
      type: i18nProvider.translate(`resources.class.types.${classData.type}`),
      episodeIndex: episodeData.number,
      published: classData.publicationDate ? 'Oui' : 'Non',
      expert: extractUser(episodeData.expert),
      expertExterne:
        episodeData.expert &&
        establishment.region &&
        episodeData.expert.regionId !== establishment.region.id
          ? 'E'
          : '',
      firstAnimateur: extractUser(episodeData.animateur_1),
      secondAnimateur: extractUser(episodeData.animateur_2),
      region: String(establishment?.region?.name),
      episodes: {
        0: {
          dateStart:
            classData.episodes[0] && classData.episodes[0].dateStart
              ? moment(classData.episodes[0].dateStart).format('D/M/YYYY HH:mm')
              : '',
        },
        1: {
          dateStart:
            classData.episodes[1] && classData.episodes[1].dateStart
              ? moment(classData.episodes[1].dateStart).format('D/M/YYYY HH:mm')
              : '',
        },
        2: {
          dateStart:
            classData.episodes[2] && classData.episodes[2].dateStart
              ? moment(classData.episodes[2].dateStart).format('D/M/YYYY HH:mm')
              : '',
        },
      },
      establishment: {
        id: establishment.id ? establishment.id : '',
        category: establishment.category
          ? categories[establishment.category]
          : '',
        name: establishment.name ? establishment.name : '',
        academy: establishment.referenceAcademy
          ? establishment.referenceAcademy
          : '',
        address: establishment.address ? establishment.address : '',
        city: establishment.city ? establishment.city : '',
        postalCode: establishment.postalCode
          ? `="${establishment.postalCode}"`
          : '',
        type: establishment.type ? types[establishment.type] : '',
        isEducationalCity: [true, false].includes(
          establishment.isEducationalCity,
        )
          ? { true: 'Oui', false: 'Non' }[
              establishment.isEducationalCity.toString()
            ]
          : '',
        phone: establishment.phone ? `="${establishment.phone}"` : '',
        establishmentReferent: establishment?.contactByCollegereferentid
          ? extractFullname(establishment.contactByCollegereferentid)
          : '',
      },
      teacher: {
        fullname: episodeData.teacher
          ? extractFullname(episodeData.teacher)
          : '',
      },
      room: episodeData.classroomNumber,
      firstGuest: episodeData.firstGuest ? episodeData.firstGuest : '',
      secondGuest: episodeData.secondGuest ? episodeData.secondGuest : '',
    };
  });
};

export default async function exporter(episodes: Episode[]): Promise<string> {
  return jsonExport(await FlattenEpisode(episodes), {
    rename: [
      'Date classe',
      'Nom Classe',
      'Niveau',
      'Type',
      'Episode',
      'Publié',
      'Expert',
      'Email Expert',
      'Tel Expert',
      'Organisme Expert',
      'Expert Externe',
      'Animateur',
      'Email Animateur',
      'Tel Animateur',
      'Organisme Animateur',
      'Animateur2',
      'Email Anim2',
      'Tel Anim2',
      'Organisme Anim2',
      'Region EJ',
      '1ere Session',
      '2eme Session',
      '3eme Session',
      'Id du site',
      'Type de site',
      'Nom public',
      'Academie',
      'Adresse',
      'Ville',
      'Code Postal',
      'Classement',
      'Cite Educative',
      'Tel fixe site',
      'Referent Collège',
      'Enseignant Referent Classe',
      'Salle',
      'Invite',
      'Invite2',
    ],
    rowDelimiter: ';',
  });
}
