import React from 'react';
import { SelectInput } from 'react-admin';

interface SelectFilterInputProps {
  model: string;
  source: string;
  values: string[];
}

const SelectFilterInput = (props: SelectFilterInputProps) => {
  const { model, source, values, ...otherProps } = props;

  return (
    <SelectInput
      choices={values.map(value => {
        return { id: value, name: `${model}.${source}s.${value}` }; // Specific to Origin, we can maybe pass a translation function
      })}
      source={source}
      {...otherProps}
    />
  );
};

export default SelectFilterInput;
