import React from 'react';
import {
  Datagrid,
  DateField,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  FunctionField,
  NumberField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  required,
  useTranslate,
  SimpleShowLayout,
  FormDataConsumer,
  Filter,
  ShowController,
  ShowView,
  Pagination,
} from 'react-admin';
import TranslateField from 'components/translateField';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddressField,
  DateTimeInput,
  TranslateChoices,
  PartialSearchInput,
  TrainingStatusField,
  exporter,
  downloadCSVForExcel,
  ListActions,
  DateInput,
} from 'components';
import RegionFilter from 'components/RegionFilter';
import { ValidateButton, InvalidateButton } from './buttons';
import {
  additionalShowProps,
  getRoles,
  isDataManager,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from '../../technical/auth/services';
import AbsentButton from './buttons/AbsentButton';
import { fiveJuly } from '../../components/DateInput';

const types = ['new', 'expert', 'renewal', 'expert_renewal', 'other'];

const roles = getRoles();

const validateDateTimesInput = (value, allValues) => {
  return value >= allValues.dateStart
    ? undefined
    : 'La date de fin doit être après la date de début';
};

const ConditionalShowButtons = props => {
  const { record } = props;
  return record && !record.invalidationDate && !record.validationDate ? (
    <>
      <ValidateButton record={record} />
      <InvalidateButton record={record} />
      <AbsentButton record={record} />
    </>
  ) : null;
};

const UserTrainingPast = props => {
  const translate = useTranslate();

  return (
    <ReferenceManyField
      reference="user_training"
      target="training_id"
      label="Intervenants"
      pagination={<Pagination />}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          reference="animator"
          source="user_id"
          label={translate('training.attendees.attendee')}
        >
          <FunctionField
            render={(user: any) => `${user.firstName} ${user.lastName}`}
          />
        </ReferenceField>
        <TrainingStatusField />
        <ConditionalShowButtons />
      </Datagrid>
    </ReferenceManyField>
  );
};

const UserTrainingInTheFuture = props => {
  const translate = useTranslate();
  return (
    <ReferenceManyField
      reference="user_training"
      target="training_id"
      label="Intervenants"
      pagination={<Pagination />}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          reference="animator"
          source="user_id"
          label={translate('training.attendees.attendee')}
          link="show"
        >
          <FunctionField
            render={(user: any) => `${user.firstName} ${user.lastName}`}
          />
        </ReferenceField>
        <ReferenceField
          reference="animator"
          source="user_id"
          label={translate('resources.user.fields.phone')}
          link={false}
        >
          <TextField source="phone" />
        </ReferenceField>
        <ReferenceField
          reference="animator"
          source="user_id"
          label={translate('resources.user.fields.email')}
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </ReferenceManyField>
  );
};

const AllTrainingListExpand = props => {
  const { record, ...rest } = props;
  return (
    <Show
      {...rest}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        {record.dateStart && new Date(record.dateStart) <= new Date() ? (
          <UserTrainingPast />
        ) : (
          <UserTrainingInTheFuture />
        )}
      </SimpleShowLayout>
    </Show>
  );
};

const TrainingFilters = props => (
  <Filter {...props}>
    <PartialSearchInput source="name" />
    <SelectInput
      source="type"
      choices={TranslateChoices(types, 'training.types')}
    />
    <DateInput
      fixedHour={0}
      fixedMinute={1}
      label="Du"
      source="dateStart._gt"
      alwaysOn
    />
    <DateInput
      fixedHour={23}
      fixedMinute={59}
      label="Au"
      source="dateStart._lt"
      alwaysOn
    />
    {shouldSeeAllRegionsRoles(roles) && (
      <RegionFilter label="Région" source="regionId" />
    )}
  </Filter>
);

const TrainingToPlanFilters = props => (
  <Filter {...props}>
    <PartialSearchInput source="name" />
    <SelectInput
      source="type"
      choices={TranslateChoices(types, 'training.types')}
    />
    <DateInput
      fixedHour={0}
      fixedMinute={1}
      label="Du"
      source="updatedAt._gt"
      alwaysOn
    />
    <DateInput
      fixedHour={23}
      fixedMinute={59}
      label="Au"
      source="updatedAt._lt"
      alwaysOn
    />
    {shouldSeeAllRegionsRoles(roles) && (
      <RegionFilter label="Région" source="regionId" />
    )}
  </Filter>
);
const ConditionalDateStartField = props => {
  const { record } = props;
  if (record?.dateStart) {
    return <DateField source="dateStart" record={record} showTime />;
  }
  return <FunctionField render={() => `A planifier`} />;
};

ConditionalDateStartField.defaultProps = {
  addLabel: true,
  label: 'resources.training.fields.dateStart',
};

const ConditionalDateEndField = props => {
  const { record } = props;
  if (record?.dateEnd) {
    return <DateField source="dateEnd" record={record} showTime />;
  }
  return <FunctionField source="dateEnd" render={() => `A planifier`} />;
};
ConditionalDateEndField.defaultProps = {
  addLabel: true,
  label: 'resources.training.fields.dateEnd',
};

export const AllTrainingList = (props: any) => {
  const { resource } = props;
  const filter =
    resource === 'training_to_validate'
      ? {
          user_trainings: {
            validationDate: { _is_null: true },
            invalidationDate: { _is_null: true },
          },
          dateStart: { _lte: new Date() },
        }
      : {};
  return (
    <List
      {...props}
      filters={<TrainingFilters />}
      filter={filter}
      filterDefaultValues={{
        dateStart: {
          _gt: fiveJuly()
            .hour(0)
            .minute(0)
            .toISOString(),
          _lt: fiveJuly()
            .add(1, 'years')
            .hour(23)
            .minute(59)
            .toISOString(),
        },
      }}
      sort={{ field: 'dateStart', order: 'DESC' }}
      perPage={25}
      {...(isShowOnlyRoles(roles) ? { bulkActionButtons: false } : {})}
      actions={<ListActions />}
      exporter={records =>
        exporter(records).then(csv => downloadCSVForExcel(csv, 'formations'))
      }
    >
      <Datagrid rowClick="show" expand={<AllTrainingListExpand />}>
        <TextField source="name" />
        <TranslateField source="type" namespace="training.types" />
        <AddressField />
        <ConditionalDateStartField />
        <ConditionalDateEndField />
        <NumberField source="nbSeats" />
        <NumberField source="nbSeatsAvailable" />
        <ReferenceField source="regionId" reference="region">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const TrainingToPlanList = (props: any) => {
  return (
    <List
      {...props}
      filters={<TrainingToPlanFilters />}
      filter={{
        nbSeats: 1,
        user_trainings: {
          validationDate: { _is_null: true },
          invalidationDate: { _is_null: true },
        },
        dateStart: { _is_null: true },
      }}
      filterDefaultValues={{
        updatedAt: {
          _gt: fiveJuly()
            .hour(0)
            .minute(0)
            .toISOString(),
          _lt: fiveJuly()
            .add(1, 'years')
            .hour(23)
            .minute(59)
            .toISOString(),
        },
      }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      {...(isShowOnlyRoles(roles) ? { bulkActionButtons: false } : {})}
      actions={<ListActions />}
      exporter={records =>
        exporter(records).then(csv =>
          downloadCSVForExcel(csv, 'formation_a_planifier'),
        )
      }
    >
      <Datagrid
        rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'}
        expand={<AllTrainingListExpand />}
      >
        <TextField source="name" />
        <TranslateField source="type" namespace="training.types" />
        <DateField source="createdAt" />
        <AddressField />
        <ReferenceField source="regionId" reference="region">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const useStyles = makeStyles({
  root: {
    width: '256px',
  },
});

const DateTimeStartInput = () => {
  const form = useForm();
  const classes = useStyles();

  return (
    <DateTimeInput
      label="Date de début"
      source="dateStart"
      onChange={value => {
        form.change('dateEnd', value);
      }}
      className={classes.root}
    />
  );
};

const NbSeats = (
  <FormDataConsumer>
    {({ _formData, record, ...rest }) => {
      const form = useForm();
      return (
        <NumberInput
          source="nbSeats"
          onChange={() => {
            const beforeUpdateNbSeats = record?.nbSeats;
            const beforeUpdateNbSeatsAvailable = record?.nbSeatsAvailable;
            const afterUpdateNbSeats = form?.getFieldState('nbSeats')?.value;
            let newNbSeatsAvailable = afterUpdateNbSeats;
            if (beforeUpdateNbSeats > 0) {
              newNbSeatsAvailable =
                beforeUpdateNbSeatsAvailable +
                (afterUpdateNbSeats - beforeUpdateNbSeats);
            }

            form.change(
              'nbSeatsAvailable',
              newNbSeatsAvailable && newNbSeatsAvailable > 0
                ? newNbSeatsAvailable
                : 0,
            );
          }}
          {...rest}
        />
      );
    }}
  </FormDataConsumer>
);

export const TrainingEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" />
        <FormDataConsumer>
          {({ record }) => {
            return (
              <SelectInput
                source="type"
                choices={TranslateChoices(types, 'training.types')}
                validate={[required()]}
                disabled={record?.nbSeats - record?.nbSeatsAvailable > 0}
                style={{ width: '256px' }}
              />
            );
          }}
        </FormDataConsumer>

        <DateTimeStartInput />
        <DateTimeInput
          source="dateEnd"
          label="Date de fin"
          validate={validateDateTimesInput}
        />
        <TextInput source="address" />
        <TextInput source="postalCode" />
        <TextInput source="city" />
        {NbSeats}
        {shouldSeeAllRegionsRoles(roles) && (
          <ReferenceInput
            source="regionId"
            reference="region"
            filterToQuery={searchText => ({
              name: { _ilike: `%${searchText}%` },
            })}
            validate={[required()]}
          >
            <AutocompleteInput
              optionText="name"
              options={{ autoComplete: 'no' }}
            />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Edit>
  );
};

export const TrainingCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" />
        <SelectInput
          source="type"
          choices={TranslateChoices(types, 'training.types')}
          validate={[required()]}
        />
        <DateTimeStartInput />
        <DateTimeInput
          source="dateEnd"
          label="Date de fin"
          validate={validateDateTimesInput}
        />
        <TextInput source="address" />
        <TextInput source="postalCode" />
        <TextInput source="city" />
        {NbSeats}
        {isDataManager(roles) && (
          <ReferenceInput
            source="regionId"
            reference="region"
            filterToQuery={searchText => ({
              name: { _ilike: `%${searchText}%` },
            })}
            validate={[required()]}
          >
            <AutocompleteInput
              optionText="name"
              options={{ autoComplete: 'no' }}
            />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export const TrainingShow = (props: any) => {
  return (
    <ShowController {...props}>
      {controllerProps => (
        <ShowView
          {...props}
          {...controllerProps}
          {...additionalShowProps(roles)}
        >
          <TabbedShowLayout {...props}>
            <Tab label="Informations">
              <TextField source="name" />
              <TranslateField namespace="training.types" source="training" />
              <DateField source="dateStart" showTime />
              <DateField source="dateEnd" showTime />
              <FunctionField
                source="address"
                render={(training: any) =>
                  training.address
                    ? `${training.address} ${training.postalCode} ${training.city}`
                    : ''
                }
              />
              <TextField source="nbSeats" />
              <ReferenceField reference="region" source="regionId">
                <TextField source="name" />
              </ReferenceField>
            </Tab>
            {controllerProps.record &&
            controllerProps.record.dateStart &&
            new Date(controllerProps.record.dateStart) <= new Date() ? (
              <Tab label="Participants">
                <UserTrainingPast />
              </Tab>
            ) : (
              <Tab label="Participants">
                <UserTrainingInTheFuture />
              </Tab>
            )}
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};
