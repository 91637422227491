import React from 'react';
import { DateTimeInput as DTInput } from '@sklinet/react-admin-date-inputs';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minHeight: '52px',
    '& div': {
      minHeight: '48px',
      '& input': {
        paddingTop: '23px',
        paddingBottom: '6px',
        paddingLeft: '12px',
      },
    },
  },
});

function DateTimeInput(props: any) {
  const style = useStyles();

  return (
    <DTInput
      className={style.root}
      parse={value => value || null}
      providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
      options={{
        format: 'dd/MM/yyyy, HH:mm',
        ampm: false,
        clearable: true,
      }}
      {...props}
    />
  );
}

export default DateTimeInput;
