import React from 'react';
import { SelectInput, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  referenceInput: {
    marginBottom: '-18px',
  },
});

const RegionFilter = ({ source, ...otherProps }) => {
  const classes = useStyles();
  return (
    <ReferenceInput
      source={source}
      reference="region"
      className={classes.referenceInput}
      label="Région"
      allowEmpty
      {...otherProps}
    >
      <SelectInput
        source="name"
        reference="establishment"
        label="Région"
        emptyText="Vider le filtre"
      />
    </ReferenceInput>
  );
};

export default RegionFilter;
