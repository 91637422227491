import { webAuth } from 'technical/auth/authProvider';
import * as React from 'react';
import { useNotify } from 'react-admin';
import LoginPage from './login';

function LoginProviderCallbackScreen() {
  const notify = useNotify();
  const [loading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    webAuth.parseHash((err, authResult) => {
      if (err) {
        global.console.error(err);
        notify(err.errorDescription);
        setIsLoading(false);
        return;
      }

      if (authResult && authResult.accessToken && authResult.idToken) {
        global.console.info('authResult', authResult);

        localStorage.setItem('authResult', JSON.stringify(authResult));
        localStorage.setItem('token', authResult.idToken);

        // reset location to rebootstrap react and correctly get everything from localstorage
        window.location.href = '/';
      } else {
        setIsLoading(false);
      }
    });
  }, [notify]);

  return <LoginPage loading={loading} />;
}

export default LoginProviderCallbackScreen;
