import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  referenceInput: {
    marginBottom: '-18px',
  },
});

const ReferenceSearchInput = ({
  source,
  reference,
  filterToQuery,
  optionText,
  label,
  ...props
}: any) => {
  const classes = useStyles();
  return (
    <ReferenceInput
      source={source}
      reference={reference}
      className={classes.referenceInput}
      filterToQuery={filterToQuery}
      allowEmpty
      label={label || `resources.${props?.resource}.fields.${source}`}
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  );
};

export default ReferenceSearchInput;
